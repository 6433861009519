import React from 'react';
import './Skills.css';

function Skills() {
  return (
    <section className="skills" id="skills">
      <div className="heading">
        <h2>Skills</h2>
        <span>My Skills</span>
      </div>
      <div className="skills-container">
        <div className="bars">
          <div className="bars-box">
            <h3>HTML</h3>
            <span>94%</span>
            <div className="light-bar"></div>
            <div className="percent-bar html-bar"></div>
          </div>
          <div className="bars-box">
            <h3>CSS</h3>
            <span>81%</span>
            <div className="light-bar"></div>
            <div className="percent-bar css-bar"></div>
          </div>
          <div className="bars-box">
            <h3>JavaScript</h3>
            <span>60%</span>
            <div className="light-bar"></div>
            <div className="percent-bar js-bar"></div>
          </div>
          <div className="bars-box">
            <h3>Python</h3>
            <span>62%</span>
            <div className="light-bar"></div>
            <div className="percent-bar python-bar"></div>
          </div>
          <div className="bars-box">
            <h3>Linux</h3>
            <span>70%</span>
            <div className="light-bar"></div>
            <div className="percent-bar linux-bar"></div>
          </div>
          <div className="bars-box">
            <h3>C++</h3>
            <span>30%</span>
            <div className="light-bar"></div>
            <div className="percent-bar cpp-bar"></div>
          </div>
          {/* Agrega más bloques de habilidades según sea necesario */}
        </div>
        <div className="skills-img">
          <img src="img/skills.png" alt="" />
        </div>
      </div>
    </section>
  );
}

export default Skills;
