import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ArticleDetail = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    fetch(`https://tempestgf.zapto.org/api/articles/${id}`)
      .then((response) => response.json())
      .then((data) => setArticle(data))
      .catch((error) => console.error('Error fetching article:', error));
  }, [id]);

  if (!article) {
    return (
      <div className="flex items-center justify-center min-h-screen text-[var(--text-color)] bg-[var(--bg-color)]">
        <p className="text-xl font-medium">Cargando...</p>
      </div>
    );
  }

  return (
    <div className="font-source-code-pro text-[var(--text-color)] bg-[var(--bg-color)] dark:bg-[var(--bg-color-night)] min-h-screen py-24 sm:py-32">
      <div className="container mx-auto px-6 md:px-12">
        <div className="w-full max-w-4xl mx-auto p-8 bg-[var(--container-bg-color)] dark:bg-[var(--container-bg-color-night)] shadow-2xl rounded-3xl transition-shadow hover:shadow-[0_10px_35px_rgba(0,0,0,0.15)]">
          
          {/* Imagen destacada */}
          <img 
            src="/path-to-image.jpg" 
            alt="Featured" 
            className="w-full h-64 object-cover rounded-lg mb-6"
          />

          {/* Título */}
          <h1 className="text-4xl lg:text-5xl font-extrabold text-center mb-4 text-[var(--main-color)] dark:text-[var(--main-color-night)]">
            {article.title}
          </h1>

          {/* Detalles del artículo */}
          <div className="flex flex-col sm:flex-row justify-between items-center text-sm lg:text-base text-[var(--subtle-text-color)] dark:text-[var(--subtle-text-color-night)] mb-6">
            <p>Publicado por <span className="font-bold">Autor Desconocido</span> el {new Date(article.date).toLocaleDateString()}</p>
            <p>{Math.floor(Math.random() * 1000) + 100} Lecturas</p>
          </div>

          {/* Contenido del artículo */}
          <div
            className="prose lg:prose-lg dark:prose-invert text-[var(--text-color)] leading-7 mb-6 transition-colors"
            dangerouslySetInnerHTML={{ __html: article.content }}
          />

          {/* Cita estilizada */}
          <blockquote className="border-l-4 border-[var(--main-color)] dark:border-[var(--main-color-night)] pl-4 italic text-xl mb-6">
            "Este es un ejemplo de una cita destacada del artículo."
          </blockquote>

          {/* Botones de compartir en redes sociales */}
          <div className="flex justify-center space-x-4 my-6">
            <button className="bg-[var(--main-color)] text-white py-2 px-4 rounded-full hover:bg-[var(--hover-color)] transition-all">Compartir en Twitter</button>
            <button className="bg-[var(--main-color)] text-white py-2 px-4 rounded-full hover:bg-[var(--hover-color)] transition-all">Compartir en Facebook</button>
            <button className="bg-[var(--main-color)] text-white py-2 px-4 rounded-full hover:bg-[var(--hover-color)] transition-all">Compartir en LinkedIn</button>
          </div>

          {/* Sección de artículos relacionados */}
          <div className="mt-10">
            <h2 className="text-2xl font-bold mb-4">Artículos relacionados</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Artículo relacionado 1 */}
              <div className="bg-[var(--container-bg-color)] dark:bg-[var(--container-bg-color-night)] p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                <h3 className="font-bold text-xl mb-2">Título de artículo relacionado 1</h3>
                <p className="text-sm">Un pequeño resumen de este artículo relacionado...</p>
                <a href="/related-article-1" className="text-[var(--main-color)] dark:text-[var(--main-color-night)] hover:underline mt-2 block">Leer más</a>
              </div>

              {/* Artículo relacionado 2 */}
              <div className="bg-[var(--container-bg-color)] dark:bg-[var(--container-bg-color-night)] p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                <h3 className="font-bold text-xl mb-2">Título de artículo relacionado 2</h3>
                <p className="text-sm">Un pequeño resumen de este artículo relacionado...</p>
                <a href="/related-article-2" className="text-[var(--main-color)] dark:text-[var(--main-color-night)] hover:underline mt-2 block">Leer más</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleDetail;
