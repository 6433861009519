import React, { useState } from 'react';
import './Admin.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Registro de componentes necesarios de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Admin = () => {
  const data = {
    cpu: "25%",
    memory: "60%",
    gpu: "40%",
    disk: "70%",
    ethernet: "100Mbps"
  };

  const cpuChartData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
    datasets: [{
      label: 'Uso de CPU',
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      borderColor: 'rgba(75,192,192,1)',
    }]
  };

  const memoryChartData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
    datasets: [{
      label: 'Uso de Memoria',
      data: [28, 48, 40, 19, 96, 27, 100],
      fill: false,
      borderColor: 'rgba(153,102,255,1)',
    }]
  };

  const diskChartData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
    datasets: [{
      label: 'Uso de Disco',
      data: [12, 19, 3, 5, 2, 3, 9],
      fill: false,
      borderColor: 'rgba(255,159,64,1)',
    }]
  };

  const [tasks, setTasks] = useState([
    { id: 1, task: 'Revisar logs del servidor', completed: false },
    { id: 2, task: 'Actualizar parches de seguridad', completed: true },
    { id: 3, task: 'Respaldar base de datos', completed: false },
  ]);

  const activities = [
    { id: 1, activity: 'El usuario Juan ingresó al sistema', timestamp: '2024-06-18 10:00' },
    { id: 2, activity: 'Respaldo del servidor completado', timestamp: '2024-06-18 09:30' },
    { id: 3, activity: 'Registro de nuevo usuario: Alicia', timestamp: '2024-06-18 08:45' },
  ];

  return (
    <div className="admin-panel">
      <h2>Panel de Administración</h2>
      <div className="grid-container">
        <div className="dashboard">
          {Object.keys(data).map((key) => (
            <div className="dashboard-item" key={key}>
              <h3>{key.toUpperCase()}</h3>
              <p>{data[key]}</p>
            </div>
          ))}
        </div>
        <div className="charts">
          <div className="chart-container">
            <Line data={cpuChartData} options={{ responsive: true }} />
          </div>
          <div className="chart-container">
            <Line data={memoryChartData} options={{ responsive: true }} />
          </div>
          <div className="chart-container">
            <Line data={diskChartData} options={{ responsive: true }} />
          </div>
        </div>
        <div className="tasks">
          <h3>Tareas Pendientes</h3>
          <ul>
            {tasks.map(task => (
              <li key={task.id} className={`task ${task.completed ? 'completed' : ''}`}>
                {task.task}
              </li>
            ))}
          </ul>
        </div>
        <div className="recent-activities">
          <h3>Actividades Recientes</h3>
          {activities.map(activity => (
            <div key={activity.id} className="activity">
              <p>{activity.activity}</p>
              <span>{activity.timestamp}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Admin;
