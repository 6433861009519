import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';

const DetallePublicacion = () => {
  const { id } = useParams();
  const [publicacion, setPublicacion] = useState(null);
  const [respuestas, setRespuestas] = useState([]);
  const [error, setError] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [nuevaRespuesta, setNuevaRespuesta] = useState('');

  const checkLogin = useCallback(async () => {
    try {
      const response = await axios.get('https://tempestgf.zapto.org/api/checkLogin', {
        withCredentials: true,
      });
      setLoggedIn(response.data.loggedIn);
    } catch (error) {
      console.error('Error fetching login status:', error);
      setLoggedIn(false);
    }
  }, []);

  const fetchPublicacion = useCallback(async () => {
    try {
      const response = await axios.get(`https://tempestgf.zapto.org/api/publicaciones/${id}`);
      setPublicacion(response.data);
    } catch (error) {
      console.error('Error fetching publicacion:', error);
      setError('Error al cargar la publicación');
    }
  }, [id]);

  const fetchRespuestas = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://tempestgf.zapto.org/api/publicaciones/${id}/respuestas`
      );
      setRespuestas(response.data);
    } catch (error) {
      console.error('Error fetching respuestas:', error);
      setError('Error al cargar las respuestas');
    }
  }, [id]);

  useEffect(() => {
    checkLogin();
    fetchPublicacion();
    fetchRespuestas();
  }, [checkLogin, fetchPublicacion, fetchRespuestas]);

  const handleAgregarRespuesta = async () => {
    if (!loggedIn) {
      setError('No estás autorizado para agregar una respuesta. Por favor, inicia sesión.');
      return;
    }

    try {
      await axios.post(
        `https://tempestgf.zapto.org/api/publicaciones/${id}/respuestas`,
        {
          respuesta: nuevaRespuesta,
        },
        { withCredentials: true }
      );
      fetchRespuestas();
      setNuevaRespuesta('');
    } catch (error) {
      console.error('Error adding respuesta:', error);
      setError('Error al agregar respuesta');
    }
  };

  const formattedDate = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      return 'Fecha no válida';
    } else {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return date.toLocaleString('es-ES', options);
    }
  };

  return (
    <div className="font-source-code-pro text-[var(--text-color)] bg-[var(--bg-color)] min-h-screen py-24 sm:py-32">
      <div className="container mx-auto px-4 sm:px-6 lg:px-12">
        <main className="py-12 sm:py-16 px-4 sm:px-8 lg:px-10 bg-[var(--bg-color)] rounded-2xl shadow-xl transition-shadow duration-300">
          <div className="max-w-3xl mx-auto bg-[var(--container-bg-color)] dark:bg-[var(--container-bg-color-night)] rounded-3xl p-8 sm:p-12 lg:p-16 shadow-2xl">
            {error && (
              <p className="text-center text-red-500 mb-4">{error}</p>
            )}
            {publicacion ? (
              <>
                <h2 className="text-3xl font-bold mb-6 text-[var(--main-color)]">
                  {publicacion.titulo}
                </h2>
                {/* Información del autor */}
                <div className="flex items-center mb-6">
                  <img
                    src={`https://tempestgf.zapto.org/${publicacion.avatar}`}
                    alt={`Avatar de ${publicacion.autor}`}
                    className="w-10 h-10 rounded-full mr-3"
                  />
                  <span className="text-lg font-medium">{publicacion.autor}</span>
                  <span className="text-sm text-gray-500 ml-auto">
                    {formattedDate(publicacion.fecha_creacion)}
                  </span>
                </div>
                {/* Contenido de la publicación */}
                <div className="prose dark:prose-invert mb-8">
                  <p>{publicacion.contenido}</p>
                </div>
                {/* Respuestas */}
                <h3 className="text-2xl font-semibold mb-4">
                  Respuestas ({respuestas.length})
                </h3>
                <div className="space-y-6">
                  {respuestas.map((respuesta) => (
                    <div
                      key={respuesta.id}
                      className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md"
                    >
                      <div className="flex items-center mb-2">
                        <img
                          src={`https://tempestgf.zapto.org/${respuesta.avatar}`}
                          alt={`Avatar de ${respuesta.autor}`}
                          className="w-8 h-8 rounded-full mr-2"
                        />
                        <span className="font-medium">{respuesta.autor}</span>
                        <span className="text-sm text-gray-500 ml-auto">
                          {formattedDate(respuesta.fecha_creacion)}
                        </span>
                      </div>
                      <div className="prose dark:prose-invert">
                        <p>{respuesta.respuesta}</p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Agregar respuesta */}
                {loggedIn ? (
                  <div className="mt-8">
                    <h3 className="text-2xl font-semibold mb-4">
                      Agregar Respuesta
                    </h3>
                    <textarea
                      value={nuevaRespuesta}
                      onChange={(e) => setNuevaRespuesta(e.target.value)}
                      placeholder="Escribe tu respuesta aquí..."
                      className="w-full h-32 px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)]"
                    />
                    <button
                      onClick={handleAgregarRespuesta}
                      className="mt-4 px-6 py-3 bg-[var(--main-color)] text-white rounded-md font-semibold hover:bg-[var(--hover-color)] transition-colors"
                    >
                      Enviar Respuesta
                    </button>
                  </div>
                ) : (
                  <p className="text-center text-red-500 mt-6">
                    Inicia sesión para agregar una respuesta.
                  </p>
                )}
              </>
            ) : (
              <p className="text-center text-lg font-medium text-[var(--text-color)]">
                Cargando publicación...
              </p>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default DetallePublicacion;
