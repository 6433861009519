import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Foro.css';

const Foro = () => {
  const [publicaciones, setPublicaciones] = useState([]);

  useEffect(() => {
    const fetchPublicaciones = async () => {
      try {
        const response = await axios.get('https://tempestgf.zapto.org/api/publicaciones');
        const publicacionesConRespuestas = await Promise.all(response.data.map(async (post) => {
          // Obtener respuestas para cada publicación
          const respuestasResponse = await axios.get(`https://tempestgf.zapto.org/api/publicaciones/${post.id}/respuestas`);
          const respuestas = respuestasResponse.data;  // Suponemos que aquí obtenemos un array de respuestas
          return {
            ...post,
            respuestas: respuestas,
          };
        }));
        setPublicaciones(publicacionesConRespuestas);
      } catch (error) {
        console.error('Error al obtener las publicaciones:', error);
      }
    };
  
    fetchPublicaciones();
  }, []);
  

  // Función para mostrar las últimas dos respuestas
  const handleMostrarUltimasRespuestas = (postId) => {
    const updatedPublicaciones = publicaciones.map(post => {
      if (post.id === postId) {
        return {
          ...post,
        };
      }
      return post;
    });
    setPublicaciones(updatedPublicaciones);
  };

  return (
    <div className="foro-container">
      <h1>Foro de Discusión</h1>
      <div className="categorias">
        <h2>Categorías</h2>
        <ul>
          <li><a href="#">Programación</a></li>
          <li><a href="#">Hacking Ético</a></li>
          <li><a href="#">Hardware</a></li>
        </ul>
      </div>
      <div className="publicaciones">
        <h2>Publicaciones Recientes</h2>
        {publicaciones.map((post) => (
          <div key={post.id} className="post">
            <Link to={`/publicaciones/${post.id}`} className="post-link">
              <div className="post-header">
                <div className="autor-info">
                  <img src={`https://tempestgf.zapto.org/${post.avatar}`} alt={`Avatar de ${post.autor}`} className="avatar" />
                  <span className="post-author">{post.autor}</span>
                </div>
                <span className="post-date">Publicado: {post.fecha_formateada}</span>
              </div>
              <div className="post-categories">
                {post.categoria.split(',').map((categoria, index) => (
                  <span key={index} className="categoria">{categoria}</span>
                ))}
              </div>
              <div className="post-tags">
                {post.tags.split(',').map((tag, index) => (
                  <span key={index} className="tag">{tag}</span>
                ))}
              </div>
              <div className="post-content">
                <p>{post.contenido}</p>
              </div>
              <div className="post-stats">
                {post.respuestas && (
                  <span className="stat">{post.respuestas.length} Respuestas</span>
                )}
                <span className="stat">{post.visitas} Visitas</span>
              </div>
              <div className="post-actions">
                <button className="btn-reaccion">👍</button>
                <button className="btn-reaccion">❤️</button>
                <button className="btn-reaccion">👏</button>
              </div>
            </Link>
            {post.respuestas && (
              <div className="respuestas">
                <h3>Últimas Respuestas</h3>
                {post.respuestas.slice(-2).map((respuesta) => (
                  <div key={respuesta.id} className="respuesta">
                    <div className="respuesta-header">
                      <span className="respuesta-author">{respuesta.autor}</span>
                      <span className="respuesta-date">Publicado: {respuesta.fecha_creacion}</span>
                    </div>
                    <div className="respuesta-content">
                      <p>{respuesta.respuesta}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {post.respuestas && post.respuestas.length > 2 && (
              <button onClick={() => handleMostrarUltimasRespuestas(post.id)}>Mostrar Últimas Respuestas</button>
            )}
          </div>
        ))}
      </div>
      <div className="new-post-button">
        <Link to="/new-post" className="btn-new-post">Crear nueva publicación</Link>
      </div>
      <div className="pagination">
        <button className="btn-pagination">Anterior</button>
        <button className="btn-pagination">1</button>
        <button className="btn-pagination">2</button>
        <button className="btn-pagination">3</button>
        <button className="btn-pagination">Siguiente</button>
      </div>
    </div>
  );
};

export default Foro;
