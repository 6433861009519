import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BlogPost = ({ content }) => (
  <div className="container">
    <ReactMarkdown>{content}</ReactMarkdown>
  </div>
);

const PublishArticle = ({ onArticlePublished }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState('');
  const [image, setImage] = useState(null);
  const [publishDate, setPublishDate] = useState(new Date());

  const handleImageUpload = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('image', file);

      const response = await fetch('https://tempestgf.zapto.org/api/upload-image', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      setImage(data.imagePath);
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const article = { 
      title, 
      description, 
      content, 
      metaDescription, 
      categories, 
      tags: tags.split(',').map(tag => tag.trim()),
      image,  
      publishDate: publishDate.toISOString().slice(0, 19).replace('T', ' ')
    };

    fetch('https://tempestgf.zapto.org/api/articles', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(article),
    })
    .then(response => response.json())
    .then(data => {
      console.log('New article added:', data);
      onArticlePublished(data);
      setTitle('');
      setDescription('');
      setContent('');
      setMetaDescription('');
      setCategories([]);
      setTags('');
      setImage(null);
    })
    .catch(error => console.error('Error adding article:', error));
  };

  return (
    <div className="publish-article-container">
      <form onSubmit={handleSubmit}>
        <div>
          <label>Título:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Descripción:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div>
          <label>Contenido:</label>
          <ReactQuill 
            value={content} 
            onChange={setContent} 
            required 
          />
        </div>
        <div>
          <label>Meta Descripción:</label>
          <textarea
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          ></textarea>
        </div>
        <div>
          <label>Categorías:</label>
          <select 
            multiple
            value={categories} 
            onChange={(e) => setCategories([...e.target.selectedOptions].map(option => option.value))}>
            <option value="Tecnología">Tecnología</option>
            <option value="Ciencia">Ciencia</option>
            <option value="Salud">Salud</option>
            <option value="Educación">Educación</option>
          </select>
        </div>
        <div>
          <label>Etiquetas:</label>
          <input
            type="text"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder="Separar etiquetas con comas"
          />
        </div>
        <div>
          <label>Imagen Principal:</label>
          <button type="button" onClick={handleImageUpload}>Subir Imagen</button>
          {image && <p>Imagen subida: {image}</p>}
        </div>
        <div>
          <label>Fecha de Publicación:</label>
          <input 
            type="datetime-local" 
            value={publishDate.toISOString().slice(0, 16)}
            onChange={(e) => setPublishDate(new Date(e.target.value))}
          />
        </div>
        <button type="submit">Publicar Artículo</button>
      </form>
    </div>
  );
};

export default PublishArticle;


