import React from 'react';
import './Contact.css'
function Contact({ msg }) {
  return (
    <section className="contact" id="contact">
      <div className="heading">
        <h2>Contact</h2>
        <span>Send a Message</span>
      </div>
      <div className="contact-form">
        <form action="{{ url_for('index') }}" method="post">
          <p>
            Your Name: <br />
            <input type="text" placeholder="Your name" />
          </p>
          <p>
            Your Email: <br />
            <input type="email" placeholder="Your email ..." />
          </p>
          <p>
            Subject: <br />
            <input type="text" placeholder="Your subject ..." />
          </p>
          <p>
            Message: <br />
            <textarea placeholder="Write your request ..." />
          </p>
          <p value="Send">
            <input className="contact-button" id="submit" name="submit" type="submit" value="Send"></input>
          </p>
          <div className="msg">{msg}</div>
        </form>
      </div>
    </section>
  );
}

export default Contact;
