import React, { useEffect, useState } from 'react';
import './Blog.css';
import Article from './Article';
import { useNavigate } from 'react-router-dom';

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://tempestgf.zapto.org/api/articles')  // Asegúrate de que la API esté correcta
      .then(response => response.json())
      .then(data => setArticles(data))
      .catch(error => console.error('Error fetching articles:', error));
  }, []);

  const handleReadMore = (id) => {
    navigate(`/articles/${id}`); // Navega a la ruta del artículo en detalle
  };

  return (
    <div className="blog-container">
      <main>
        <div className="container">
          <h1>Blog</h1>
          {articles.length > 0 ? (
            articles.map(article => (
              <Article key={article.id} article={article} onReadMore={handleReadMore} />
            ))
          ) : (
            <p>No se encontraron artículos.</p>
          )}
        </div>
      </main>
    </div>
  );
};

export default Blog;
