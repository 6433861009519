import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PublishArticle = () => {
  const { id } = useParams(); // Para edición de artículos
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [focusKeyword, setFocusKeyword] = useState('');
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [tags, setTags] = useState('');
  const [allTags, setAllTags] = useState([]);
  const [image, setImage] = useState(null);
  const [publishDate, setPublishDate] = useState(new Date());
  const [readingTime, setReadingTime] = useState(0);
  const [status, setStatus] = useState('draft');
  const [errors, setErrors] = useState({});
  const [preview, setPreview] = useState(false);
  const [notification, setNotification] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Nuevos estados para verificar si el usuario es administrador
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Verificar si el usuario es administrador al cargar el componente
    const checkAdminStatus = async () => {
      try {
        const response = await fetch('https://tempestgf.zapto.org/api/checkAdmin', {
          method: 'GET',
          credentials: 'include',
        });
        const data = await response.json();
        if (data.isAdmin) {
          setIsAdmin(true);
          // Cargar categorías y etiquetas solo si es administrador
          fetchAllCategoriesAndTags();
          // Si hay un ID, cargar el artículo para editar
          if (id) {
            loadArticleForEditing(id);
          }
        } else {
          toast.error('No tienes permisos para acceder a esta página.');
          navigate('/');
        }
      } catch (error) {
        console.error('Error al verificar permisos:', error);
        toast.error('Error al verificar permisos.');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
    checkAdminStatus();
  }, [id, navigate]);

  const fetchAllCategoriesAndTags = () => {
    // Obtener todas las categorías y etiquetas existentes
    fetch('https://tempestgf.zapto.org/api/categories')
      .then((res) => res.json())
      .then((data) => setAllCategories(data));

    fetch('https://tempestgf.zapto.org/api/tags')
      .then((res) => res.json())
      .then((data) => setAllTags(data));
  };

  const loadArticleForEditing = (articleId) => {
    fetch(`https://tempestgf.zapto.org/api/articles/${articleId}`)
      .then((res) => res.json())
      .then((data) => {
        setTitle(data.title);
        setSlug(data.slug);
        setSummary(data.summary);
        setContent(data.content);
        setMetaTitle(data.meta_title);
        setMetaDescription(data.meta_description);
        setFocusKeyword(data.focus_keyword);
        setCategories(data.categories);
        setTags(data.tags.join(', '));
        setImage(data.image_path);
        setPublishDate(new Date(data.publish_date));
        setReadingTime(data.reading_time);
        setStatus(data.status);
      })
      .catch((error) => console.error('Error loading article:', error));
  };

  const handleImageUpload = async (files) => {
    const file = files[0];
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await fetch('https://tempestgf.zapto.org/api/upload-image', {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });
      const data = await response.json();
      setImage(data.imagePath);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const calculateReadingTime = (text) => {
    const wordsPerMinute = 200;
    const cleanText = DOMPurify.sanitize(text, { USE_PROFILES: { html: false } });
    const textLength = cleanText.split(/\s+/).length;
    return Math.ceil(textLength / wordsPerMinute);
  };

  const handleContentChange = (value) => {
    setContent(value);
    setReadingTime(calculateReadingTime(value));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!title.trim()) formErrors.title = 'El título es obligatorio.';
    if (!content.trim()) formErrors.content = 'El contenido no puede estar vacío.';
    if (!summary.trim()) formErrors.summary = 'El resumen es obligatorio.';
    if (!categories.length) formErrors.categories = 'Seleccione al menos una categoría.';
    return formErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    setNotification('');

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setIsSubmitting(false);
      return;
    }

    const article = {
      title,
      slug: slug || title.toLowerCase().replace(/ /g, '-'),
      summary,
      content,
      meta_title: metaTitle || title,
      meta_description: metaDescription || summary,
      focus_keyword: focusKeyword,
      categories,
      tags: tags.split(',').map((tag) => tag.trim()),
      image_path: image,
      publish_date: publishDate.toISOString().slice(0, 19).replace('T', ' '),
      reading_time: readingTime,
      status,
      // No es necesario enviar author_id ya que el backend puede obtenerlo de la sesión
    };

    try {
      const method = id ? 'PUT' : 'POST';
      const url = id
        ? `https://tempestgf.zapto.org/api/articles/${id}`
        : 'https://tempestgf.zapto.org/api/articles';

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(article),
      });
      if (response.ok) {
        const data = await response.json();
        setNotification('El artículo se ha guardado exitosamente.');
        setIsSubmitting(false);
        navigate(`/articles/${data.id}`);
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.message}`);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error saving article:', error);
      toast.error('Hubo un error al guardar el artículo.');
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setSlug('');
    setSummary('');
    setContent('');
    setMetaTitle('');
    setMetaDescription('');
    setFocusKeyword('');
    setCategories([]);
    setTags('');
    setImage(null);
    setPublishDate(new Date());
    setReadingTime(0);
    setStatus('draft');
    setErrors({});
  };

  const handleTagInput = (e) => {
    setTags(e.target.value);
  };

  const addSuggestedTag = (tag) => {
    const currentTags = tags.split(',').map((t) => t.trim());
    if (!currentTags.includes(tag)) {
      setTags([...currentTags, tag].join(', '));
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen text-[var(--text-color)] bg-[var(--bg-color)]">
        <p className="text-xl font-medium">Cargando...</p>
      </div>
    );
  }

  if (!isAdmin) {
    return null;
  }

  return (
    <div className="font-source-code-pro text-[var(--text-color)] bg-[var(--bg-color)] min-h-screen py-24 sm:py-32">
      <ToastContainer />
      <div className="container mx-auto px-4 sm:px-6 lg:px-12">
        <main className="py-12 sm:py-16 px-4 sm:px-8 lg:px-10 bg-[var(--bg-color)] rounded-2xl shadow-xl hover:shadow-2xl transition-shadow duration-300">
          <div className="max-w-4xl mx-auto bg-[var(--container-bg-color)] dark:bg-[var(--container-bg-color-night)] rounded-3xl p-8 sm:p-12 lg:p-16 shadow-2xl">
            <h1 className="text-3xl lg:text-4xl font-bold text-center mb-8">
              {id ? 'Editar artículo' : 'Publicar nuevo artículo'}
            </h1>

            {/* Notificación */}
            {notification && (
              <div className="mb-6 p-4 text-green-800 bg-green-200 rounded-lg">
                {notification}
              </div>
            )}

            {/* Barra de navegación del formulario */}
            <div className="flex justify-between items-center mb-6">
              {/* Botones de estado */}
              <div>
                <button
                  type="button"
                  onClick={() => setStatus('draft')}
                  className={`py-2 px-4 rounded-l-lg ${
                    status === 'draft' ? 'bg-[var(--main-color)] text-white' : 'bg-gray-200'
                  }`}
                >
                  Borrador
                </button>
                <button
                  type="button"
                  onClick={() => setStatus('published')}
                  className={`py-2 px-4 rounded-r-lg ${
                    status === 'published' ? 'bg-[var(--main-color)] text-white' : 'bg-gray-200'
                  }`}
                >
                  Publicar
                </button>
              </div>
              {/* Botón de previsualización */}
              <button
                type="button"
                onClick={() => setPreview(!preview)}
                className="bg-gray-200 py-2 px-4 rounded-lg hover:bg-gray-300 transition-all"
              >
                {preview ? 'Editar' : 'Previsualizar'}
              </button>
            </div>

            {/* Previsualización en tiempo real */}
            {preview ? (
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">{title || 'Título del artículo'}</h2>
                <p className="text-sm text-gray-500 mb-2">
                  Por { /* Aquí podrías mostrar el nombre del autor */ } |{' '}
                  {new Date(publishDate).toLocaleDateString()}
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(content) || '<p>Contenido del artículo...</p>',
                  }}
                  className="prose dark:prose-dark max-w-none"
                />
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Título */}
                <div>
                  <label className="block text-lg font-semibold mb-2">Título:</label>
                  <input
                    type="text"
                    className={`w-full px-4 py-2 border ${
                      errors.title ? 'border-red-500' : 'border-gray-300'
                    } rounded-lg bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] transition`}
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setSlug(e.target.value.toLowerCase().replace(/ /g, '-'));
                    }}
                    required
                  />
                  {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
                </div>

                {/* Slug */}
                <div>
                  <label className="block text-lg font-semibold mb-2">Slug (URL):</label>
                  <input
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] transition"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    placeholder="ejemplo-mi-articulo"
                  />
                </div>

                {/* Resumen */}
                <div>
                  <label className="block text-lg font-semibold mb-2">Resumen:</label>
                  <textarea
                    className={`w-full px-4 py-2 border ${
                      errors.summary ? 'border-red-500' : 'border-gray-300'
                    } rounded-lg bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] transition`}
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    placeholder="Escribe un breve resumen del artículo..."
                  ></textarea>
                  {errors.summary && <p className="text-red-500 text-sm mt-1">{errors.summary}</p>}
                </div>

                {/* Contenido */}
                <div>
                  <label className="block text-lg font-semibold mb-2">Contenido:</label>
                  <ReactQuill
                    value={content}
                    onChange={handleContentChange}
                    required
                    className={`rounded-lg focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] bg-white dark:bg-gray-800 dark:text-white ${
                      errors.content ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.content && <p className="text-red-500 text-sm mt-1">{errors.content}</p>}
                  <p className="mt-2 text-sm">Tiempo estimado de lectura: {readingTime} minutos</p>
                </div>

                {/* Meta Título */}
                <div>
                  <label className="block text-lg font-semibold mb-2">Meta Título (SEO):</label>
                  <input
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] transition"
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                  />
                </div>

                {/* Meta Descripción */}
                <div>
                  <label className="block text-lg font-semibold mb-2">Meta Descripción (SEO):</label>
                  <textarea
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] transition"
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                  ></textarea>
                </div>

                {/* Palabra clave de enfoque */}
                <div>
                  <label className="block text-lg font-semibold mb-2">Palabra Clave de Enfoque (SEO):</label>
                  <input
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] transition"
                    value={focusKeyword}
                    onChange={(e) => setFocusKeyword(e.target.value)}
                    placeholder="Escribe la palabra clave principal del artículo"
                  />
                </div>

                {/* Categorías */}
                <div>
                  <label className="block text-lg font-semibold mb-2">Categorías:</label>
                  <select
                    multiple
                    className={`w-full px-4 py-2 border ${
                      errors.categories ? 'border-red-500' : 'border-gray-300'
                    } rounded-lg bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] transition`}
                    value={categories}
                    onChange={(e) =>
                      setCategories([...e.target.selectedOptions].map((option) => option.value))
                    }
                  >
                    {allCategories.map((cat) => (
                      <option key={cat.id} value={cat.name}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                  {errors.categories && (
                    <p className="text-red-500 text-sm mt-1">{errors.categories}</p>
                  )}
                </div>

                {/* Etiquetas */}
                <div>
                  <label className="block text-lg font-semibold mb-2">Etiquetas:</label>
                  <input
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] transition"
                    value={tags}
                    onChange={handleTagInput}
                    placeholder="Separar etiquetas con comas"
                  />
                  {/* Etiquetas sugeridas */}
                  <div className="mt-2 flex flex-wrap gap-2">
                    {allTags.map((tag) => (
                      <span
                        key={tag.id}
                        onClick={() => addSuggestedTag(tag.name)}
                        className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 py-1 px-3 rounded-full cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-600"
                      >
                        {tag.name}
                      </span>
                    ))}
                  </div>
                </div>

                {/* Imagen Principal */}
                <div>
                  <label className="block text-lg font-semibold mb-2">Imagen Principal:</label>
                  <div
                    className="w-full h-32 border-dashed border-2 border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400"
                    onClick={() => document.getElementById('imageUpload').click()}
                    onDrop={(e) => {
                      e.preventDefault();
                      handleImageUpload(e.dataTransfer.files);
                    }}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    {image ? (
                      <img src={image} alt="Imagen subida" className="h-full object-cover rounded-lg" />
                    ) : (
                      <p>Arrastra y suelta una imagen o haz clic para subir</p>
                    )}
                  </div>
                  <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => handleImageUpload(e.target.files)}
                  />
                </div>

                {/* Fecha de Publicación */}
                <div>
                  <label className="block text-lg font-semibold mb-2">Fecha de Publicación:</label>
                  <input
                    type="datetime-local"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] transition"
                    value={publishDate.toISOString().slice(0, 16)}
                    onChange={(e) => setPublishDate(new Date(e.target.value))}
                  />
                </div>

                {/* Botones de acción */}
                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={resetForm}
                    className="bg-gray-200 py-2 px-4 rounded-lg hover:bg-gray-300 transition-all"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-[var(--main-color)] text-white py-2 px-6 rounded-lg font-semibold hover:bg-[var(--hover-color)] transition-all"
                  >
                    {isSubmitting ? 'Procesando...' : 'Guardar'}
                  </button>
                </div>
              </form>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default PublishArticle;
