// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MobileWheelPrivacyPolicy.css */

.privacy-policy-container {
    padding: 50px 10%;
    background: var(--bg-color);
    color: var(--text-color);
    line-height: 1.6;
    font-size: var(--p-font);
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.privacy-policy-container h1 {
    font-size: var(--big-font);
    margin-bottom: 20px;
    color: var(--main-color);
    text-align: center;
}

.privacy-policy-container p {
    margin-bottom: 15px;
}

.privacy-policy-container ul {
    list-style: disc inside;
    margin-left: 20px;
    margin-bottom: 20px;
}

.privacy-policy-container li {
    margin-bottom: 10px;
}

.privacy-policy-container strong {
    color: var(--main-color);
}

.privacy-policy-container a {
    color: var(--main-color);
    text-decoration: underline;
    transition: color 0.3s ease;
}

.privacy-policy-container a:hover {
    color: var(--hover-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/MobileWheel/PrivacyPolicy.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC;IACI,iBAAiB;IACjB,2BAA2B;IAC3B,wBAAwB;IACxB,gBAAgB;IAChB,wBAAwB;IACxB,SAAO;IACP,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,0BAA0B;IAC1B,mBAAmB;IACnB,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;IACxB,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* MobileWheelPrivacyPolicy.css */\n\n.privacy-policy-container {\n    padding: 50px 10%;\n    background: var(--bg-color);\n    color: var(--text-color);\n    line-height: 1.6;\n    font-size: var(--p-font);\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n}\n\n.privacy-policy-container h1 {\n    font-size: var(--big-font);\n    margin-bottom: 20px;\n    color: var(--main-color);\n    text-align: center;\n}\n\n.privacy-policy-container p {\n    margin-bottom: 15px;\n}\n\n.privacy-policy-container ul {\n    list-style: disc inside;\n    margin-left: 20px;\n    margin-bottom: 20px;\n}\n\n.privacy-policy-container li {\n    margin-bottom: 10px;\n}\n\n.privacy-policy-container strong {\n    color: var(--main-color);\n}\n\n.privacy-policy-container a {\n    color: var(--main-color);\n    text-decoration: underline;\n    transition: color 0.3s ease;\n}\n\n.privacy-policy-container a:hover {\n    color: var(--hover-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
