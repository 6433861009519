import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Post = () => {
  const [titulo, setTitulo] = useState('');
  const [contenido, setContenido] = useState('');
  const [categoria, setCategoria] = useState('');
  const [tags, setTags] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        'https://tempestgf.zapto.org/api/publicaciones',
        {
          titulo,
          contenido,
          categoria,
          tags: tags.split(',').map((tag) => tag.trim()),
        },
        {
          withCredentials: true,
        }
      );
      navigate('/foro');
    } catch (error) {
      console.error('Error al crear la publicación:', error);
      setError('Error al crear la publicación. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <div className="font-source-code-pro text-[var(--text-color)] bg-[var(--bg-color)] min-h-screen py-24 sm:py-32">
      <div className="container mx-auto px-4 sm:px-6 lg:px-12">
        <main className="py-12 sm:py-16 px-4 sm:px-8 lg:px-10 bg-[var(--bg-color)] rounded-2xl shadow-xl transition-shadow duration-300">
          <div className="max-w-2xl mx-auto bg-[var(--container-bg-color)] dark:bg-[var(--container-bg-color-night)] rounded-3xl p-8 sm:p-12 lg:p-16 shadow-2xl">
            <h2 className="text-3xl text-center mb-6 text-[var(--main-color)] font-semibold">
              Crear Nueva Publicación
            </h2>
            {error && (
              <p className="text-center text-red-500 mb-4">{error}</p>
            )}
            <form onSubmit={handleSubmit} className="flex flex-col gap-6">
              {/* Título */}
              <div className="flex flex-col">
                <label htmlFor="titulo" className="text-lg font-medium">
                  Título:
                </label>
                <input
                  type="text"
                  id="titulo"
                  name="titulo"
                  value={titulo}
                  onChange={(e) => setTitulo(e.target.value)}
                  required
                  className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)]"
                />
              </div>
              {/* Contenido */}
              <div className="flex flex-col">
                <label htmlFor="contenido" className="text-lg font-medium">
                  Contenido:
                </label>
                <textarea
                  id="contenido"
                  name="contenido"
                  value={contenido}
                  onChange={(e) => setContenido(e.target.value)}
                  rows="6"
                  required
                  className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)]"
                ></textarea>
              </div>
              {/* Categoría */}
              <div className="flex flex-col">
                <label htmlFor="categoria" className="text-lg font-medium">
                  Categoría:
                </label>
                <select
                  id="categoria"
                  name="categoria"
                  value={categoria}
                  onChange={(e) => setCategoria(e.target.value)}
                  required
                  className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)]"
                >
                  <option value="">Selecciona una categoría</option>
                  <option value="Programación">Programación</option>
                  <option value="Hacking Ético">Hacking Ético</option>
                  <option value="Hardware">Hardware</option>
                </select>
              </div>
              {/* Tags */}
              <div className="flex flex-col">
                <label htmlFor="tags" className="text-lg font-medium">
                  Tags (separados por comas):
                </label>
                <input
                  type="text"
                  id="tags"
                  name="tags"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] dark:text-white focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)]"
                />
              </div>
              {/* Botón de enviar */}
              <button
                type="submit"
                className="mt-4 px-6 py-3 bg-[var(--main-color)] text-white rounded-md font-semibold hover:bg-[var(--hover-color)] transition-transform transform hover:scale-105"
              >
                Publicar
              </button>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Post;
