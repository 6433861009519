// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Skills.css */
.skills-container {
    width: 50%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem;
}

.skills-img img {
	width: 80%;
	padding-left: 4rem;
	object-position: center;
}

.bars-box {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
	margin-bottom: 1rem;
}

.bars-box h3, span {
	font-size: 1.1rem;
	font-weight: 500;
}

.light-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0.5rem;
	background: var(--secondary-color);
	border-radius: 0.5rem;
}

.percent-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 0.5rem;
	background: var(--main-color);
	border-radius: 0.5rem;
}

.html-bar {
	width: 94%;
}

.css-bar {
	width: 81%;
}

.js-bar {
	width: 60%;
}

.python-bar {
	width: 62%;
}

.linux-bar {
	width: 70%;
}

.cpp-bar {
	width: 30%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Skills/Skills.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,UAAU;IACV,iBAAiB;IACjB,cAAc;IACd,aAAa;AACjB;;AAEA;CACC,UAAU;CACV,kBAAkB;CAClB,uBAAuB;AACxB;;AAEA;CACC,kBAAkB;CAClB,aAAa;CACb,8BAA8B;CAC9B,mBAAmB;CACnB,eAAe;CACf,mBAAmB;AACpB;;AAEA;CACC,iBAAiB;CACjB,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;CAClB,SAAS;CACT,OAAO;CACP,WAAW;CACX,cAAc;CACd,kCAAkC;CAClC,qBAAqB;AACtB;;AAEA;CACC,kBAAkB;CAClB,SAAS;CACT,OAAO;CACP,cAAc;CACd,6BAA6B;CAC7B,qBAAqB;AACtB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;AACX","sourcesContent":["/* Skills.css */\n.skills-container {\n    width: 50%;\n    max-width: 1000px;\n    margin: 0 auto;\n    padding: 2rem;\n}\n\n.skills-img img {\n\twidth: 80%;\n\tpadding-left: 4rem;\n\tobject-position: center;\n}\n\n.bars-box {\n\tposition: relative;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tpadding: 1rem 0;\n\tmargin-bottom: 1rem;\n}\n\n.bars-box h3, span {\n\tfont-size: 1.1rem;\n\tfont-weight: 500;\n}\n\n.light-bar {\n\tposition: absolute;\n\tbottom: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 0.5rem;\n\tbackground: var(--secondary-color);\n\tborder-radius: 0.5rem;\n}\n\n.percent-bar {\n\tposition: absolute;\n\tbottom: 0;\n\tleft: 0;\n\theight: 0.5rem;\n\tbackground: var(--main-color);\n\tborder-radius: 0.5rem;\n}\n\n.html-bar {\n\twidth: 94%;\n}\n\n.css-bar {\n\twidth: 81%;\n}\n\n.js-bar {\n\twidth: 60%;\n}\n\n.python-bar {\n\twidth: 62%;\n}\n\n.linux-bar {\n\twidth: 70%;\n}\n\n.cpp-bar {\n\twidth: 30%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
