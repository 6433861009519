import React from 'react';
import './Services.css'

const Services = () => {
    return (
        <section className="services" id="services">
            <div className="heading">
                <h2>Services</h2>
                <span>-</span>
            </div>
            <div className="services-content">
                <div className="services-box">
                    <i className='bx bx-code-alt'></i>
                    <h3>Frontend <br />Development</h3>
                    <a href="/">Learn More</a>
                </div>
                <div className="services-box">
                    <i className='bx bx-server'></i>
                    <h3>Backend <br />Development</h3>
                    <a href="/">Learn More</a>
                </div>
                <div className="services-box">
                    <i className='bx bx-brush'></i>
                    <h3>UI/UX <br />Design</h3>
                    <a href="/">Learn More</a>
                </div>
            </div>
        </section>
    );
}

export default Services;
