import React from 'react';
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>

      <p>
        <strong>Introduction</strong>
      </p>
      <p>
        This privacy policy outlines how our Android application ("Steering Wheel Activity") collects, uses, and protects your personal information. By using this application, you agree to the terms of this privacy policy.
      </p>

      <p>
        <strong>Information We Collect</strong>
      </p>
      <ul>
        <li>
          <strong>Sensor Data:</strong> We collect accelerometer data to provide motion-based controls within the app. This data is used solely within the application and is not transmitted or shared with any third-party services.
        </li>
        <li>
          <strong>Network Information:</strong> The app may access your device’s network information to establish a connection with a server for communication purposes. This includes accessing the device’s IP address and network interfaces for connection discovery.
        </li>
        <li>
          <strong>Vibration and Sound:</strong> The app uses the device's vibration motor and sound capabilities to enhance the user experience during interaction with the app’s features. No data is collected or shared from these activities.
        </li>
      </ul>

      <p>
        <strong>How We Use the Information</strong>
      </p>
      <ul>
        <li>
          <strong>In-App Features:</strong> Sensor data is used to control the steering and acceleration functions of the app. Network information is used to connect to the server for gameplay or remote control functions.
        </li>
        <li>
          <strong>User Feedback:</strong> Vibration and sound feedback are provided to improve user interaction with the app.
        </li>
      </ul>

      <p>
        <strong>Data Sharing and Storage</strong>
      </p>
      <ul>
        <li>
          <strong>Data Sharing:</strong> We do not share your data with any third parties. All data collected by the app is processed locally on your device.
        </li>
        <li>
          <strong>Data Storage:</strong> The app does not store any personal data on the device or in external databases. Temporary data, such as connection details, may be stored during the session but is not retained after the app is closed.
        </li>
      </ul>

      <p>
        <strong>Security</strong>
      </p>
      <p>
        We implement appropriate technical measures to protect the personal information we collect from unauthorized access, disclosure, alteration, or destruction.
      </p>

      <p>
        <strong>User Rights</strong>
      </p>
      <ul>
        <li>
          <strong>Access and Control:</strong> You have the right to access the data collected by the app. You can also request the deletion of your data or opt out of data collection by uninstalling the application.
        </li>
      </ul>

      <p>
        <strong>Changes to this Privacy Policy</strong>
      </p>
      <p>
        We may update this privacy policy from time to time. Any changes will be posted within the app, and your continued use of the app after any modifications indicate your acceptance of the new terms.
      </p>

      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have any questions or concerns about this privacy policy, please contact us at [Your Contact Information].
      </p>
    </div>
  );
};

export default PrivacyPolicy;
