import React from 'react';
import './Home.css';

function Home() {
  return (
    <section className="home" id="home">
      
      
      <div className="social">
        <a href="https://github.com/tempestgf"><i className='bx bxl-github'></i></a>
        <a href="https://twitter.com/tempestgf"><i className='bx bxl-twitter' ></i></a>
        <a href="https://www.pinterest.es/tempestgf"><i className='bx bxl-pinterest' ></i></a>
        <a href="https://app.hackthebox.com/profile/346176"><i className='bx bx-cube' ></i></a> 
        <a href="https://codepen.io/Tempestgf"><i className='bx bxl-codepen' ></i></a> 
      </div>

      <div className="home-text">
        <h1>TEMPEST</h1>
        <h2>Netrunner</h2>
        <p>Problem solving and thinking out of the matrix</p>
        <a href="#contact" className="btn-cyberpunk">Contact Me</a>
      </div>

      <div className="cyber-container">
        <img className="night-image" src={require('./soldieraura.png')} alt="Cyberpunk Soldier" />
      </div>

      <div className="scroll-down">
        <a href="#about">
          <i className='bx bx-mouse' ></i>
          <span>Scroll Down</span>
          <i className='bx bx-caret-down' ></i>
        </a>
      </div>

      <canvas id="backgroundCanvas"></canvas>
    </section>
  );
}

export default Home;
