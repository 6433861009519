import { useState, useEffect } from 'react';

export function DarkMode() {
    // Check localStorage or default to dark mode
    const [darkMode, setDarkMode] = useState(() => {
      const currentTheme = localStorage.getItem('theme');
      if (currentTheme) {
        return currentTheme === 'dark';
      } else {
        localStorage.setItem('theme', 'dark');
        return true; 
      }
    });
  
    useEffect(() => {
      // Update document body classList only after component mounts
      document.body.classList.toggle('active', darkMode);
      
      // Cleanup function to remove the classList change when component unmounts
      return () => {
        document.body.classList.remove('active');
      };
    }, [darkMode]);
  
    const toggleDarkMode = () => {
      setDarkMode(!darkMode);
    };
  
    return (
      <div className="dark-mode-toggle" onClick={toggleDarkMode}>
        {darkMode ? (
          <i className="bx bx-sun"></i>
        ) : (
          <i className="bx bx-moon"></i>
        )}
      </div>
    );
}
