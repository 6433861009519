import React, { useState, useEffect } from 'react';
import './UserProfile.css';

function UserProfile() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await fetch('https://tempestgf.zapto.org/api/userProfile', {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data);
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Error fetching user profile');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async (updatedProfile) => {
    try {
      const response = await fetch('https://tempestgf.zapto.org/api/userProfile', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(updatedProfile),
      });
      if (response.ok) {
        fetchUserProfile(); // Recargar perfil después de la actualización
        setSuccessMessage('Profile updated successfully');
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
      setError('Error updating user profile');
    }
  };

  const handleUploadProfilePicture = async (file) => {
    const formData = new FormData();
    formData.append('profile_picture', file);

    try {
      const response = await fetch('https://tempestgf.zapto.org/api/uploadProfilePicture', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });
      if (response.ok) {
        fetchUserProfile(); // Recargar perfil después de subir la imagen
        setSuccessMessage('Profile picture uploaded successfully');
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      setError('Error uploading profile picture');
    }
  };

  if (loading) {
    return <div className="user-profile"><div className="loading">Loading...</div></div>;
  }

  if (error) {
    return <div className="user-profile"><div className="error">{error}</div></div>;
  }

  if (!user) {
    return <div className="user-profile"><div className="error">No user data</div></div>;
  }

  return (
    <div className="user-profile">
      <h1>User Profile</h1>
      <div className="profile-header">
        <div className="profile-picture-wrapper">
          <label htmlFor="upload-profile-picture" className="upload-icon">
            <input
              type="file"
              id="upload-profile-picture"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => handleUploadProfilePicture(e.target.files[0])}
            />
            <div className="upload-icon-content" onClick={() => document.getElementById('upload-profile-picture').click()}>
              +
            </div>
          </label>
          {user.profile_picture && (
            <img
              src={`https://tempestgf.zapto.org/${user.profile_picture}`}
              alt="Profile"
              className="profile-picture avatar"
            />
          )}
        </div>
        <div className="profile-info">
          <p><strong>ID:</strong> {user.id}</p>
          <p><strong>Username:</strong> {user.username}</p>
          <p><strong>Email:</strong> {user.email}</p>
          <p><strong>Joined:</strong> {new Date(user.joined).toLocaleDateString()}</p>
        </div>
      </div>

      <section className="profile-details">
        <h2>Profile Details</h2>
        <p><strong>Full Name:</strong> {user.full_name}</p>
        <p><strong>Phone Number:</strong> {user.phone_number}</p>
        <p><strong>Biography:</strong> {user.bio || 'No biography provided'}</p>
      </section>

      <section className="update-profile">
        <h2>Update Profile</h2>
        {successMessage && <div className="success">{successMessage}</div>}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const updatedProfile = {
              full_name: e.target.full_name.value,
              phone_number: e.target.phone_number.value,
              bio: e.target.bio.value,
            };
            handleUpdateProfile(updatedProfile);
          }}
        >
          <label htmlFor="full_name">Full Name:</label>
          <input type="text" id="full_name" name="full_name" defaultValue={user.full_name} required />
          <label htmlFor="phone_number">Phone Number:</label>
          <input type="tel" id="phone_number" name="phone_number" defaultValue={user.phone_number} required />
          <label htmlFor="bio">Biography:</label>
          <textarea id="bio" name="bio" defaultValue={user.bio} rows="3"></textarea>
          <button type="submit">Update</button>
        </form>
      </section>
    </div>
  );
}

export default UserProfile;
