import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './DetallePublicacion.css';

const DetallePublicacion = () => {
  const { id } = useParams(); // Obtener el parámetro ID de la URL
  const [publicacion, setPublicacion] = useState(null); // Estado para almacenar los detalles de la publicación
  const [respuestas, setRespuestas] = useState([]); // Estado para almacenar las respuestas de la publicación
  const [error, setError] = useState(null); // Estado para manejar errores de carga
  const [loggedIn, setLoggedIn] = useState(false); // Estado para verificar el inicio de sesión del usuario
  const [nuevaRespuesta, setNuevaRespuesta] = useState(''); // Estado para manejar la nueva respuesta

  // Función para verificar el estado de inicio de sesión del usuario
  const checkLogin = useCallback(async () => {
    try {
      const response = await axios.get('https://tempestgf.zapto.org/api/checkLogin', { withCredentials: true });
      setLoggedIn(response.data.loggedIn);
    } catch (error) {
      console.error('Error fetching login status:', error);
      setLoggedIn(false);
    }
  }, []);

  // Función para obtener detalles de la publicación seleccionada
  const fetchPublicacion = useCallback(async () => {
    try {
      const response = await axios.get(`https://tempestgf.zapto.org/api/publicaciones/${id}`);
      const data = response.data;
      setPublicacion(data); // Guardar los detalles de la publicación
    } catch (error) {
      console.error('Error fetching publicacion:', error);
      setError('Error al cargar la publicación');
    }
  }, [id]);

  // Función para obtener respuestas de la publicación seleccionada
  const fetchRespuestas = useCallback(async () => {
    try {
      const response = await axios.get(`https://tempestgf.zapto.org/api/publicaciones/${id}/respuestas`);
      const respuestasData = response.data;

      // Obtener detalles del autor para cada respuesta
      const respuestasConAutor = await Promise.all(respuestasData.map(async (respuesta) => {
        // Consultar detalles del autor para cada respuesta
        const autorResponse = await axios.get(`https://tempestgf.zapto.org/api/autores/${respuesta.autor_id}`);
        const autorData = autorResponse.data;
        
        return {
          ...respuesta,
          autor: autorData.username, // Nombre de usuario del autor
          avatar: autorData.profile_picture // Avatar del autor
        };
      }));

      setRespuestas(respuestasConAutor); // Guardar las respuestas de la publicación con detalles del autor
    } catch (error) {
      console.error('Error fetching respuestas:', error);
      setError('Error al cargar las respuestas');
    }
  }, [id]);

  // Efecto para cargar detalles de la publicación y respuestas al montar el componente
  useEffect(() => {
    checkLogin(); // Verificar el inicio de sesión del usuario
    fetchPublicacion(); // Obtener detalles de la publicación
    fetchRespuestas(); // Obtener respuestas de la publicación
  }, [checkLogin, fetchPublicacion, fetchRespuestas]);

  // Función para manejar la adición de una nueva respuesta
  const handleAgregarRespuesta = async () => {
    if (!loggedIn) {
      setError('No estás autorizado para agregar una respuesta. Por favor, inicia sesión.');
      return;
    }

    try {
      await axios.post(`https://tempestgf.zapto.org/api/publicaciones/${id}/respuestas`, {
        respuesta: nuevaRespuesta
      }, { withCredentials: true });
      fetchRespuestas(); // Actualizar las respuestas después de agregar una nueva respuesta
      setNuevaRespuesta(''); // Limpiar el campo de nueva respuesta
    } catch (error) {
      console.error('Error adding respuesta:', error);
      setError('Error al agregar respuesta');
    }
  };

  // Función para formatear la fecha
  const formattedDate = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      return "Fecha no válida";
    } else {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return date.toLocaleString("es-ES", options);
    }
  };

  return (
    <div className="detalle-publicacion">
      {error && <p>{error}</p>}
      {publicacion && (
        <>
          <h2>{publicacion.titulo}</h2>
          <div className="autor-info">
            <img src={`https://tempestgf.zapto.org/${publicacion.avatar}`} alt={`Avatar de ${publicacion.autor}`} className="avatar" />
            <span className="post-author">{publicacion.autor}</span>
          </div>
          <p>Fecha de Publicación: {formattedDate(publicacion.fecha_creacion)}</p>
          <p>{publicacion.contenido}</p>
          <hr />
          <h3>Respuestas:</h3>
          <div className="respuestas">
            {respuestas.map(respuesta => (
              <div key={respuesta.id} className="respuesta">
                <p>{respuesta.respuesta}</p>
                <div className="autor-info">
                  <img src={`https://tempestgf.zapto.org/${respuesta.profile_picture}`} alt={`Avatar de ${respuesta.autor}`} className="avatar" />
                  <span className="post-author">{respuesta.autor}</span>
                </div>
                <p>Fecha: {formattedDate(respuesta.fecha_creacion)}</p>
              </div>
            ))}
          </div>
          <hr />
          {loggedIn ? (
            <>
              <h3>Agregar Respuesta:</h3>
              <textarea
                value={nuevaRespuesta}
                onChange={(e) => setNuevaRespuesta(e.target.value)}
                placeholder="Escribe tu respuesta aquí..."
              />
              <br />
              <button onClick={handleAgregarRespuesta}>Enviar Respuesta</button>
            </>
          ) : (
            <p>Inicia sesión para agregar una respuesta.</p>
          )}
        </>
      )}
    </div>
  );
};

export default DetallePublicacion;
