import React from 'react';
import DOMPurify from 'dompurify';

const Article = ({ article, onReadMore }) => {
  // Extraer texto plano del contenido HTML
  const getPlainText = (html) => {
    const cleanHtml = DOMPurify.sanitize(html);
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = cleanHtml;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  // Obtener vista previa del contenido sin etiquetas HTML
  const plainTextContent = getPlainText(article.content);
  const previewContent =
    plainTextContent.length > 200
      ? plainTextContent.substring(0, 200) + '...'
      : plainTextContent;

  return (
    <article
      className="bg-white dark:bg-gray-900 rounded-lg p-6 sm:p-8 shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:-translate-y-1 hover:bg-opacity-90"
      data-aos="fade-up"
    >
      {/* Imagen destacada */}
      {article.image_path && (
        <img
          src={article.image_path}
          alt={article.title}
          className="w-full h-48 object-cover rounded-lg mb-4"
        />
      )}

      <h2 className="text-2xl sm:text-3xl font-bold mb-2 text-[var(--main-color)] hover:text-[var(--hover-color)] transition-colors duration-300">
        {article.title}
      </h2>

      {/* Fecha de publicación y autor */}
      <div className="flex items-center text-sm text-gray-500 mb-4">
        {article.date && (
          <p className="mr-4">
            {new Date(article.date).toLocaleDateString()}
          </p>
        )}
        {article.author_name && (
          <p>
            Por <span className="font-medium">{article.author_name}</span>
          </p>
        )}
      </div>

      <p className="text-base sm:text-lg text-[var(--text-color)] mb-6">
        {previewContent}
      </p>

      <button
        onClick={() => onReadMore(article.id)}
        className="bg-gradient-to-r from-[var(--main-color)] to-[var(--hover-color)] text-white py-2 sm:py-3 px-6 rounded-full font-semibold hover:scale-105 transform transition-all duration-200 hover:from-[var(--secondary-color)] hover:to-[var(--hover-color)]"
      >
        Leer más...
      </button>
    </article>
  );
};

export default Article;
