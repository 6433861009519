import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
import { DarkMode } from '../Utils/DarkMode';
import { StickyNavbar } from '../Utils/StickyNavbar';
import Register from '../Session/Register';
import Login from '../Session/Login';

function Navbar() {
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const darkModeRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        checkLoginStatus();
    }, []);

    const checkLoginStatus = async () => {
        try {
            const response = await fetch('https://tempestgf.zapto.org/api/checkLogin', {
                method: 'GET',
                credentials: 'include', // Importante para enviar cookies de sesión
            });
            if (response.ok) {
                const data = await response.json();
                setIsLoggedIn(data.loggedIn);
                setUsername(data.username);
            } else {
                setIsLoggedIn(false);
                setUsername('');
            }
        } catch (error) {
            console.error('Error checking login status:', error);
            setIsLoggedIn(false);
            setUsername('');
        }
    };

    const handleLoginClick = () => {
        setShowLoginModal(true);
    };

    const handleRegisterClick = () => {
        setShowRegisterModal(true);
    };

    const handleCloseModalRegister = () => {
        setShowRegisterModal(false);
    };

    const handleCloseModalLogin = () => {
        setShowLoginModal(false);
    };

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleMobileMenuToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleLogout = async () => {
        try {
            const response = await fetch('https://tempestgf.zapto.org/api/logout', {
                method: 'POST',
                credentials: 'include', // Importante para enviar cookies de sesión
            });
            if (response.ok) {
                setIsLoggedIn(false);
                setUsername('');
                setIsDropdownOpen(false);
                window.location.href = '/'; // Redirigir a la página principal
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const handleLogin = (username) => {
        setIsLoggedIn(true);
        setUsername(username);
        setShowLoginModal(false);
    };

    return (
        <header>
            <StickyNavbar setIsMobileMenuOpen={setIsMobileMenuOpen} />
            <a href="/" className="logo">tempest</a>
            <div className="menu-icon" onClick={handleMobileMenuToggle}>☰</div>
            <ul className={`navbar ${isMobileMenuOpen ? 'active' : ''}`}>
                <li><a href="/#home">Home</a></li>
                <li><a href="/Blog">Blog</a></li>
                <li><a href="/Forum">Forum</a></li>
                <li><a href="/AI">AI</a></li>
                <li><a href="http://tempestjh5wpg3u22oql3bhz434tk6nf4zxzvvrwp477ghnx5p5bbrad.onion">Tor</a></li>
                {isLoggedIn ? (
                    <li className="dropdown" ref={dropdownRef}>
                        <a className="username" onClick={handleDropdownToggle}>{username}</a>
                        <div className={`dropdown-content ${isDropdownOpen ? 'active' : ''}`}>
                            <a href="/profile">Profile</a>
                            <a href="#" onClick={handleLogout}>Logout</a>
                        </div>
                    </li>
                ) : (
                    <>
                        <li><a onClick={handleLoginClick}>Login</a></li>
                        <li><a onClick={handleRegisterClick}>Register</a></li>
                    </>
                )}
            </ul>
            <div id="darkmode" ref={darkModeRef}><DarkMode /></div>
            {showRegisterModal && <Register onClose={handleCloseModalRegister} />}
            {showLoginModal && <Login onClose={handleCloseModalLogin} onLogin={handleLogin} />}
        </header>
    );
}

export default Navbar;
