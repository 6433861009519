import React, { useEffect, memo } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaExternalLinkAlt, FaGithub } from 'react-icons/fa';

const projectsData = [
  {
    title: 'Sitio Web de Restaurante',
    description:
      'Desarrollo de un sitio web moderno y responsivo para un restaurante, con menú interactivo y sistema de reservas en línea para mejorar la experiencia del usuario.',
    image: '/static/restaurant-demo.webp',
    githubLink: 'https://github.com/yourusername/restaurant-website',
    liveLink: '/restaurante1',
  },
  {
    title: 'Dotfiles Personalizados',
    description:
      'Colección de archivos de configuración personalizados para automatizar entornos de desarrollo y mejorar la eficiencia en múltiples sistemas operativos.',
    image: '/static/dotfiles.webp',
    githubLink: 'https://github.com/Tempestgf/dotfiles',
  },
  {
    title: 'Mobile Wheel App',
    description:
      'Aplicación Android que simula un volante para juegos de PC, proporcionando una experiencia de conducción realista mediante la conectividad móvil.',
    image: '/static/aplication-demo.webp',
    githubLink: 'https://github.com/yourusername/mobile-wheel',
    liveLink: 'https://liveprojectthree.com',
  },
];

const ProjectCard = memo(({ project }) => (
  <div
    className="bg-[var(--bg-color)] dark:bg-[var(--bg-color-night)] rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 focus-visible:outline-none focus-visible:ring-0 focus:outline-none border-none"
    data-aos="flip-left"
    tabIndex="0"
    aria-label={`Proyecto ${project.title}`}
  >
    <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
      <img
        src={project.image}
        alt={`Captura del proyecto ${project.title}`}
        className="absolute top-0 left-0 w-full h-full object-cover object-center"
        loading="lazy"
      />
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
        <div className="flex space-x-4">
          <a
            href={project.githubLink}
            className="text-white text-2xl hover:text-[var(--main-color)]"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Ver el repositorio GitHub de ${project.title}`}
          >
            <FaGithub aria-hidden="true" />
          </a>
          {project.liveLink && (
            <a
              href={project.liveLink}
              className="text-white text-2xl hover:text-[var(--main-color)]"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`Ver la demo en vivo de ${project.title}`}
            >
              <FaExternalLinkAlt aria-hidden="true" />
            </a>
          )}
        </div>
      </div>
    </div>
    <div className="p-6 flex flex-col h-64 bg-[var(--bg-color)] dark:bg-[var(--bg-color-night)] border-none">
      <h3 className="text-2xl font-bold text-[var(--text-color)] dark:text-[var(--text-color-night)] mb-3">
        {project.title}
      </h3>
      <p className="text-base text-[var(--text-color)] dark:text-[var(--text-color-night)] mb-4 flex-grow">
        {project.description}
      </p>
      <div className="flex justify-between items-center mt-auto">
        <a
          href={project.githubLink}
          className="text-sm bg-[var(--main-color)] dark:bg-[var(--main-color-night)] text-white py-2 px-4 rounded-lg hover:bg-[var(--hover-color)] dark:hover:bg-[var(--hover-night)] transition-colors duration-300"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver Código
        </a>
        {project.liveLink && (
          <a
            href={project.liveLink}
            className="text-sm bg-[var(--secondary-color)] dark:bg-[var(--secondary-color-night)] text-white py-2 px-4 rounded-lg hover:bg-[var(--hover-color)] dark:hover:bg-[var(--hover-night)] transition-colors duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            Demo en Vivo
          </a>
        )}
      </div>
    </div>
  </div>
));



function Projects() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 50,
      easing: 'ease-in-out', // Suaviza la animación
    });
  }, []);

  return (
    <section id="projects" className="py-20 bg-[var(--bg-color)] text-[var(--text-color)]">
      <div className="container mx-auto px-6 md:px-12">
        <div className="text-center mb-16">
          <h2
            className="text-4xl font-extrabold text-[var(--text-color)]"
            data-aos="fade-down"
          >
            Mis Proyectos
          </h2>
          <p
            className="text-lg mt-4 text-[var(--text-color)]"
            data-aos="fade-up"
          >
            Explora algunos de los proyectos en los que he trabajado, mostrando mis habilidades en desarrollo web y ciberseguridad.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {projectsData.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects;
