import React, { useState } from 'react';
import { Github, Mail, Trello, Calendar } from 'lucide-react';  // Importar los íconos
import styles from './SearchPage.module.css';

const SearchPage = () => {
  const [query, setQuery] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) {
      console.log('Searching for:', query);
    }
  };

  return (
    <div className={styles.searchPageContainer}>
      <main className={styles.mainContent}>
        <h2 className={styles.tagline}>Find exactly what you're looking for</h2>
        <form className={styles.searchBar} onSubmit={handleSearch}>
          <input
            type="text"
            placeholder="Search the web..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className={styles.searchInput}
          />
          <button type="submit" className={styles.searchButton}>Search</button>
        </form>

        {/* Sección de iconos de acceso rápido */}
        <div className={styles.quickAccess}>
          <a href="https://github.com" target="_blank" rel="noopener noreferrer">
            <Github size={32} />
          </a>
          <a href="https://mail.protonmail.com" target="_blank" rel="noopener noreferrer">
            <Mail size={32} />
          </a>
          <a href="https://todoist.com" target="_blank" rel="noopener noreferrer">
            <Trello size={32} />
          </a>
          <a href="https://calendar.google.com" target="_blank" rel="noopener noreferrer">
            <Calendar size={32} />
          </a>
        </div>
      </main>
    </div>
  );
};

export default SearchPage;
