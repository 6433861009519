import { useEffect, useRef } from 'react';

export function StickyNavbar({ setIsMobileMenuOpen }) {
    const headerRef = useRef(null);
    const menuRef = useRef(null);
    const navbarRef = useRef(null);

    useEffect(() => {
        const header = headerRef.current;
        const menu = menuRef.current;
        const navbar = navbarRef.current;

        const handleScroll = () => {
            if (header) {
                header.classList.toggle('shadow', window.scrollY > 0);
            }
            setIsMobileMenuOpen(false); // Cierra el menú móvil al hacer scroll
        };

        const handleMenuClick = () => {
            if (navbar) {
                navbar.classList.toggle('active');
                setIsMobileMenuOpen(prevState => !prevState); // Cambia el estado del menú móvil
            }
        };

        const handleClickOutsideNavbar = (event) => {
            if (navbar && !navbar.contains(event.target) && event.target !== menu && !menu.contains(event.target)) {
                setIsMobileMenuOpen(false); // Cierra el menú móvil si se hace clic fuera del navbar y del menú icono
            }
        };

        const closeNavbar = () => {
            if (navbar && navbar.classList.contains('active')) {
                navbar.classList.remove('active');
                setIsMobileMenuOpen(false); // Asegura que el estado refleje que el menú está cerrado
            }
        };

        window.addEventListener('scroll', handleScroll);
        document.addEventListener('click', handleClickOutsideNavbar); // Event listener para clics fuera del navbar y del menú icono

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('click', handleClickOutsideNavbar); // Limpia el event listener al desmontar el componente
            closeNavbar(); // Asegura que el menú se cierre al desmontar el componente
        };
    }, [setIsMobileMenuOpen]);

    return null; // Este componente no renderiza nada visualmente en React
}
