import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Foro = () => {
  const [publicaciones, setPublicaciones] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPublicaciones = async () => {
      try {
        const response = await axios.get('https://tempestgf.zapto.org/api/publicaciones');
        const publicacionesConRespuestas = await Promise.all(
          response.data.map(async (post) => {
            const respuestasResponse = await axios.get(
              `https://tempestgf.zapto.org/api/publicaciones/${post.id}/respuestas`
            );
            const respuestas = respuestasResponse.data;
            return { ...post, respuestas };
          })
        );
        setPublicaciones(publicacionesConRespuestas);
      } catch (error) {
        console.error('Error al obtener las publicaciones:', error);
        setError('Error al cargar las publicaciones.');
      }
    };

    fetchPublicaciones();
    AOS.init({ duration: 1000, easing: 'ease-in-out' });
  }, []);

  const handleReadMore = (id) => {
    navigate(`/publicaciones/${id}`);
  };

  return (
    <div className="font-source-code-pro text-[var(--text-color)] bg-[var(--bg-color)] min-h-screen py-24 sm:py-32">
      <div className="container mx-auto px-4 sm:px-6 lg:px-12">
        <main className="py-12 sm:py-16 px-4 sm:px-8 lg:px-10 bg-[var(--bg-color)] rounded-2xl shadow-xl hover:shadow-2xl transition-shadow duration-300">
          <div
            className="max-w-5xl mx-auto bg-[var(--container-bg-color)] dark:bg-[var(--container-bg-color-night)] rounded-3xl p-8 sm:p-12 lg:p-16 shadow-2xl"
            data-aos="fade-up"
          >
            <h1 className="text-center text-4xl sm:text-5xl lg:text-6xl font-extrabold mb-8 text-[var(--main-color)] tracking-tight">
              Foro de Discusión
            </h1>

            {error && (
              <p className="text-center text-lg font-medium text-red-500 mb-6">
                {error}
              </p>
            )}

            <div className="flex justify-center mb-8">
              <Link
                to="/new-post"
                className="bg-gradient-to-r from-[var(--main-color)] to-[var(--hover-color)] text-white py-2 sm:py-3 px-6 rounded-full font-semibold hover:scale-105 transform transition-all duration-200 hover:from-[var(--secondary-color)] hover:to-[var(--hover-color)]"
              >
                Crear nueva publicación
              </Link>
            </div>

            {publicaciones.length > 0 ? (
              <div className="space-y-8 sm:space-y-10 lg:space-y-12" data-aos="fade-up">
                {publicaciones.map((post) => (
                  <article
                    key={post.id}
                    className="bg-white dark:bg-gray-900 rounded-lg p-6 sm:p-8 shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:-translate-y-1 hover:bg-opacity-90"
                    data-aos="fade-up"
                  >
                    <Link to={`/publicaciones/${post.id}`} className="block">
                      {/* Título de la publicación */}
                      <h2 className="text-2xl sm:text-3xl font-bold mb-2 text-[var(--main-color)] hover:text-[var(--hover-color)] transition-colors duration-300">
                        {post.titulo}
                      </h2>

                      {/* Información del autor y fecha */}
                      <div className="flex items-center text-sm text-gray-500 mb-4">
                        <div className="flex items-center mr-4">
                          <img
                            src={`https://tempestgf.zapto.org/${post.avatar}`}
                            alt={`Avatar de ${post.autor}`}
                            className="w-8 h-8 rounded-full mr-2"
                          />
                          <span className="font-medium">{post.autor}</span>
                        </div>
                        <p>{new Date(post.fecha_creacion).toLocaleDateString()}</p>
                      </div>

                      {/* Categorías y etiquetas */}
                      <div className="flex flex-wrap gap-2 mb-4">
                        {post.categoria &&
                          post.categoria.split(',').map((categoria, index) => (
                            <span
                              key={index}
                              className="bg-blue-200 text-blue-800 px-2 py-1 rounded text-sm"
                            >
                              {categoria}
                            </span>
                          ))}
                        {post.tags &&
                          post.tags.split(',').map((tag, index) => (
                            <span
                              key={index}
                              className="bg-green-200 text-green-800 px-2 py-1 rounded text-sm"
                            >
                              {tag}
                            </span>
                          ))}
                      </div>

                      {/* Contenido de la publicación */}
                      <p className="text-base sm:text-lg text-[var(--text-color)] mb-6">
                        {post.contenido.length > 200
                          ? post.contenido.substring(0, 200) + '...'
                          : post.contenido}
                      </p>

                      {/* Información adicional */}
                      <div className="flex gap-4 text-sm text-gray-500">
                        {post.respuestas && (
                          <span>{post.respuestas.length} Respuestas</span>
                        )}
                        <span>{post.visitas || 0} Visitas</span>
                      </div>
                    </Link>
                  </article>
                ))}
              </div>
            ) : (
              <p className="text-center text-lg font-medium text-[var(--text-color)]">
                No se encontraron publicaciones.
              </p>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Foro;
