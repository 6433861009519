import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';

const ServerInterface = () => {
  const [ws, setWs] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [command, setCommand] = useState('');
  const [output, setOutput] = useState('');
  const [commandHistory, setCommandHistory] = useState([]);
  const [filter, setFilter] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const commonCommands = ['ls', 'pwd', 'whoami', 'uname -a', 'df -h'];

  // Referencia al área de salida
  const outputRef = useRef(null);

  // Verificar si el usuario es administrador
  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const response = await fetch('/api/checkAdmin', {
          method: 'GET',
          credentials: 'include',
        });
        const data = await response.json();
        if (data.isAdmin) {
          setIsAdmin(true);
        } else {
          toast.error('No tienes permisos para acceder a esta página.');
          navigate('/');
        }
      } catch (error) {
        console.error('Error al verificar permisos:', error);
        toast.error('Error al verificar permisos.');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
    checkAdminStatus();
  }, [navigate]);

  // Gestionar la conexión WebSocket
  useEffect(() => {
    let socket;

    const connectWebSocket = () => {
      socket = new WebSocket('wss://tempestgf.zapto.org:4000');

      socket.onopen = () => {
        console.log('[+] Conexión WebSocket establecida');
        setWs(socket);
        toast.success('Conexión establecida con el servidor.');

        // Solicitar lista de clientes al conectar
        socket.send(JSON.stringify({ type: 'list' }));
      };

      socket.onmessage = (event) => {
        const msg = JSON.parse(event.data);
        if (msg.type === 'list') {
          setClients(msg.clients);
        } else if (msg.type === 'response') {
          setOutput((prevOutput) => `${prevOutput}\nCliente ${msg.id}: ${msg.response}`);
        } else if (msg.type === 'error') {
          setOutput((prevOutput) => `${prevOutput}\nError: ${msg.message}`);
          toast.error(`Error: ${msg.message}`);
        }
      };

      socket.onerror = (error) => {
        console.error('Error en la conexión WebSocket:', error);
        toast.error('Error en la conexión WebSocket.');
      };

      socket.onclose = () => {
        console.log('[+] Conexión WebSocket cerrada, intentando reconectar...');
        setTimeout(connectWebSocket, 5000); // Reintentar cada 5 segundos
      };
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  // Actualizar la lista de clientes automáticamente cada 10 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: 'list' }));
      }
    }, 10000); // Actualizar cada 10 segundos

    return () => clearInterval(interval);
  }, [ws]);

  // Desplazar el área de salida hasta el final cuando se agrega nueva salida
  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [output]);

  // Seleccionar un cliente
  const handleSelectClient = useCallback((id) => {
    setSelectedClients((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((clientId) => clientId !== id)
        : [...prevSelected, id]
    );
  }, []);

  // Enviar un comando
  const handleSendCommand = useCallback(() => {
    if (ws && ws.readyState === WebSocket.OPEN && selectedClients.length > 0 && command) {
      selectedClients.forEach((id) => {
        ws.send(JSON.stringify({ type: 'select', id, command }));
      });
      setCommandHistory((prevHistory) => [command, ...prevHistory]);
      setCommand('');
      setSuggestions([]);
      toast.success('Comando enviado a los clientes seleccionados.');
    } else {
      toast.warn('Seleccione al menos un cliente y escriba un comando.');
    }
  }, [ws, selectedClients, command]);

  // Manejo del campo de comandos
  const handleCommandChange = (e) => {
    const value = e.target.value;
    setCommand(value);
    setSuggestions(
      value.length > 0
        ? commonCommands.filter((cmd) => cmd.startsWith(value))
        : []
    );
  };

  // Enviar comando al presionar Enter
  const handleCommandKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendCommand();
    }
  };

  // Seleccionar sugerencias
  const handleSuggestionClick = (suggestion) => {
    setCommand(suggestion);
    setSuggestions([]);
  };

  // Filtrar clientes
  const filteredClients = useMemo(
    () =>
      clients.filter(
        (client) =>
          client.id.toString().includes(filter) || client.address.includes(filter)
      ),
    [clients, filter]
  );

  if (loading) {
    return <p>Cargando...</p>;
  }

  if (!isAdmin) {
    return null;
  }

  return (
    <motion.div
      className="container mx-auto p-8 mt-24 min-h-screen bg-gray-100"
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ToastContainer />
      <motion.h2
        className="text-3xl font-bold mb-8 font-orbitron text-center text-gray-800"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Panel de Control de Clientes
      </motion.h2>

      {/* Barra de Herramientas */}
      <motion.div
        className="flex flex-col md:flex-row md:items-center mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        <div className="flex mb-4 md:mb-0">
          {/* Eliminado el botón de actualizar */}
          <input
            type="text"
            placeholder="Filtrar clientes..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="border border-gray-300 rounded px-4 py-2 bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div className="md:ml-auto mt-4 md:mt-0">
          <motion.span
            className="text-gray-800 font-semibold"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0.4 }}
          >
            Clientes seleccionados: {selectedClients.length}
          </motion.span>
        </div>
      </motion.div>

      {/* Área Principal */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Lista de Clientes */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <h3 className="text-xl font-semibold mb-4 text-gray-800">
            Clientes Conectados:
          </h3>
          <ul className="border border-gray-300 rounded p-4 h-64 overflow-auto bg-white text-gray-800">
            {filteredClients.map((client) => (
              <li
                key={client.id}
                className={`mb-2 p-2 rounded cursor-pointer flex justify-between items-center hover:bg-blue-50 ${
                  selectedClients.includes(client.id) ? 'bg-blue-100' : ''
                }`}
                onClick={() => handleSelectClient(client.id)}
              >
                <div>
                  <span className="font-medium">ID:</span> {client.id}
                  <br />
                  <span className="font-medium">IP:</span> {client.address}:
                  {client.port}
                </div>
                {selectedClients.includes(client.id) && (
                  <span className="text-green-500 font-bold">✔</span>
                )}
              </li>
            ))}
          </ul>
        </motion.div>

        {/* Comando y Historial */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.5 }}
        >
          <h3 className="text-xl font-semibold mb-4 text-gray-800">
            Enviar Comando:
          </h3>
          <div className="flex flex-col h-full">
            <div className="relative mb-4">
              <input
                type="text"
                value={command}
                onChange={handleCommandChange}
                onKeyDown={handleCommandKeyDown}
                placeholder="Escribe un comando"
                className="border border-gray-300 rounded px-4 py-2 w-full bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
              {suggestions.length > 0 && (
                <ul className="absolute bg-white border border-gray-300 rounded mt-2 w-full max-h-40 overflow-auto z-10 text-gray-800">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="p-2 hover:bg-blue-50 cursor-pointer"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="flex justify-end mb-4">
              <motion.button
                onClick={handleSendCommand}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Enviar
              </motion.button>
            </div>
            <div>
              <h4 className="font-semibold mb-2 text-gray-800">
                Historial de Comandos:
              </h4>
              <ul className="border border-gray-300 rounded p-4 h-32 overflow-auto bg-white text-gray-800">
                {commandHistory.map((cmd, index) => (
                  <li
                    key={index}
                    className="mb-1 hover:bg-blue-50 p-2 rounded cursor-pointer"
                    onClick={() => setCommand(cmd)}
                  >
                    {cmd}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Área de Salida */}
      <motion.div
        className="mt-8 mb-32"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <h3 className="text-xl font-semibold mb-4 text-gray-800">
          Salida de Clientes:
        </h3>
        <motion.pre
          ref={outputRef} // Añadido ref al área de salida
          className="bg-gray-900 text-white p-4 rounded h-[600px] overflow-auto whitespace-pre-wrap"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          {output}
        </motion.pre>
      </motion.div>
    </motion.div>
  );
};

export default ServerInterface;
