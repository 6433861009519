import React from 'react';
import './About.css'
function About() {
  return (
    <section className="about" id="about">
      <div className="heading">
        <h2>About</h2>
        <span>Introduction</span>
      </div>
      <div className="about-container">
        <div className="about-img">
          {/* <img src="img/about.jpg" alt=""> */}
        </div>
        <div className="about-text">
          <p>I stand out for being a programmer and cybersecurity analyst, so I am constantly updating myself with new technologies. I like to mess around with code flaws, exploits and vulnerabilities and look for new ones. My goal is to have a good time learning, studying and working.</p>
          <div className="information">
            <div className="info-box">
              <i className='bx bxs-user' ></i>
              <a href="https://twitter.com/Tempestgf">Tempestgf</a>
            </div>
            <div className="info-box">
              <i className='bx bxs-envelope' ></i>
              <a href="mailto:tempestgf@protonmail.com">tempestgf@protonmail.com</a>
            </div>
            <a href="{{ url_for('static', filename='CV.pdf') }}" className="btn-cyberpunk">Download CV</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
