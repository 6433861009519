import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <h2>tempest</h2>
                {/*<p className="footer-description">Some description here...</p>*/}
                <div className="footer-social">
                    <a href="https://app.hackthebox.com/profile/346176" target="_blank" rel="noopener noreferrer"><i className='bx bx-cube'></i></a>
                    <a href="https://twitter.com/tempestgf" target="_blank" rel="noopener noreferrer"><i className='bx bxl-twitter'></i></a>
                    <a href="https://github.com/tempestgf" target="_blank" rel="noopener noreferrer"><i className='bx bxl-github'></i></a> 
                </div>
                <div className="copyleft">
                    <p>&copy; 2024 tempest. Todos los derechos reservados.</p>
                </div>
            </div>
            <div className="footer-wave"></div>
        </footer>
    );
}

export default Footer;
