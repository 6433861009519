import React, { useState } from 'react';

const AI = () => {
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => setMessage(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const res = await fetch('https://tempestgf.zapto.org/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message }),
      });

      if (!res.body) {
        throw new Error('Streaming not supported');
      }

      let result = '';
      const reader = res.body.getReader();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += new TextDecoder().decode(value);
        setResponse(result);
      }

      setMessage('');
    } catch (error) {
      console.error('Error in request:', error);
      setError('Error communicating with server');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen p-5 bg-[var(--bg-color)]">
      <div className="bg-[var(--secondary-color)] p-5 rounded-lg shadow-md max-w-xl w-full text-center">
        <h1 className="text-[var(--big-font)] mb-5 text-[var(--main-color)]">Interact with AI</h1>
        <form onSubmit={handleSubmit} className="flex flex-col items-center mb-5">
          <input
            type="text"
            value={message}
            onChange={handleChange}
            placeholder="Type your message..."
            disabled={loading}
            className="w-full p-2 text-[var(--medium-font)] border-2 border-[var(--main-color)] rounded mb-3 transition-colors focus:border-[var(--hover-color)] focus:outline-none"
          />
          <button
            type="submit"
            disabled={loading}
            className="px-5 py-2 text-[var(--medium-font)] text-[var(--bg-color)] bg-[var(--main-color)] rounded transition-colors hover:bg-[var(--hover-color)]"
          >
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
        {loading && (
          <div className="border-4 border-[var(--bg-color)] border-t-4 border-t-[var(--main-color)] rounded-full w-6 h-6 animate-spin mx-auto"></div>
        )}
        {error && <p className="text-red-500 mt-3">{error}</p>}
        {response && (
          <div className="mt-5 p-3 border border-[var(--main-color)] rounded bg-[var(--bg-color)]">
            <p className="text-[var(--p-font)] text-[var(--text-color)]">{response}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AI;
