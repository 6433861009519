// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchPage_searchPageContainer__\\+zSkr {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding-top: 70px;
}

.SearchPage_mainContent__vQWzX {
  text-align: center;
}

.SearchPage_tagline__BmHbE {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.SearchPage_searchBar__M4ZGI {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.SearchPage_searchInput__e8W\\+0 {
  width: 400px;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.SearchPage_searchButton__3DiCo {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.SearchPage_searchButton__3DiCo:hover {
  background-color: #0056b3;
}

/* Estilos para la sección de iconos de acceso rápido */
.SearchPage_quickAccess__c8xCG {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.SearchPage_quickAccess__c8xCG a {
  color: #333;
  transition: transform 0.3s ease;
}

.SearchPage_quickAccess__c8xCG a:hover {
  transform: scale(1.2);
  color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Search/SearchPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,uDAAuD;AACvD;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".searchPageContainer {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n  background-color: #f5f5f5;\n  padding-top: 70px;\n}\n\n.mainContent {\n  text-align: center;\n}\n\n.tagline {\n  font-size: 1.5rem;\n  margin-bottom: 20px;\n}\n\n.searchBar {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 30px;\n}\n\n.searchInput {\n  width: 400px;\n  padding: 10px;\n  font-size: 1rem;\n  border: 2px solid #ddd;\n  border-radius: 5px;\n  margin-right: 10px;\n}\n\n.searchButton {\n  padding: 10px 20px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  font-size: 1rem;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.searchButton:hover {\n  background-color: #0056b3;\n}\n\n/* Estilos para la sección de iconos de acceso rápido */\n.quickAccess {\n  display: flex;\n  justify-content: center;\n  gap: 20px;\n  margin-top: 20px;\n}\n\n.quickAccess a {\n  color: #333;\n  transition: transform 0.3s ease;\n}\n\n.quickAccess a:hover {\n  transform: scale(1.2);\n  color: #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchPageContainer": `SearchPage_searchPageContainer__+zSkr`,
	"mainContent": `SearchPage_mainContent__vQWzX`,
	"tagline": `SearchPage_tagline__BmHbE`,
	"searchBar": `SearchPage_searchBar__M4ZGI`,
	"searchInput": `SearchPage_searchInput__e8W+0`,
	"searchButton": `SearchPage_searchButton__3DiCo`,
	"quickAccess": `SearchPage_quickAccess__c8xCG`
};
export default ___CSS_LOADER_EXPORT___;
