import React from 'react';
import './Dashboard.css';

const Dashboard = () => {
  const data = {
    cpu: "25%",
    memory: "60%",
    gpu: "40%",
    disk: "70%",
    ethernet: "100Mbps"
  };

  return (
    <div className="dashboard">
      <h2>Admin Panel</h2>
      <div className="dashboard-content">
        <div className="dashboard-item">
          <h3>CPU</h3>
          <p>{data.cpu}</p>
        </div>
        <div className="dashboard-item">
          <h3>Memory</h3>
          <p>{data.memory}</p>
        </div>
        <div className="dashboard-item">
          <h3>GPU</h3>
          <p>{data.gpu}</p>
        </div>
        <div className="dashboard-item">
          <h3>Disk</h3>
          <p>{data.disk}</p>
        </div>
        <div className="dashboard-item">
          <h3>Ethernet</h3>
          <p>{data.ethernet}</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
