import React from 'react';
import './Tests.css';

const Tests = () => {
  return (
    <div class="button-container">
      <body>
        <button class="btn">
            <span class="btn__content">Get your copy now_</span>
            <span class="btn__glitch"></span>
            <span class="btn__label">r25</span>
        </button>
      </body>
    </div>

  );
}

export default Tests;
