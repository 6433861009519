// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
    padding: 20px;
    background-color: var(--bg-color);
    color: var(--text-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: var(--medium-font);
  }
  
  .dashboard-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .dashboard-item {
    background: var(--secondary-color);
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    flex: 1 1 200px;
    max-width: 300px;
    text-align: center;
    transition: background 0.3s;
  }
  
  .dashboard-item h3 {
    margin-bottom: 10px;
    font-size: var(--p-font);
  }
  
  .dashboard-item p {
    font-size: var(--big-font);
  }
  
  .dashboard-item:hover {
    background: var(--hover-color);
  }
  
  .dashboard.dark {
    background-color: #333;
    color: #fff;
  }
  
  .dashboard.light {
    background-color: #fff;
    color: #333;
  }
  
  /* Estilos adicionales para botones y gráficos si es necesario */
  `, "",{"version":3,"sources":["webpack://./src/components/Admin/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iCAAiC;IACjC,wBAAwB;IACxB,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,6BAA6B;EAC/B;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,eAAe;EACjB;;EAEA;IACE,kCAAkC;IAClC,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,2BAA2B;EAC7B;;EAEA;IACE,mBAAmB;IACnB,wBAAwB;EAC1B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,sBAAsB;IACtB,WAAW;EACb;;EAEA,gEAAgE","sourcesContent":[".dashboard {\n    padding: 20px;\n    background-color: var(--bg-color);\n    color: var(--text-color);\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .dashboard h2 {\n    text-align: center;\n    margin-bottom: 20px;\n    font-size: var(--medium-font);\n  }\n  \n  .dashboard-content {\n    display: flex;\n    justify-content: space-around;\n    flex-wrap: wrap;\n  }\n  \n  .dashboard-item {\n    background: var(--secondary-color);\n    padding: 20px;\n    margin: 10px;\n    border-radius: 8px;\n    flex: 1 1 200px;\n    max-width: 300px;\n    text-align: center;\n    transition: background 0.3s;\n  }\n  \n  .dashboard-item h3 {\n    margin-bottom: 10px;\n    font-size: var(--p-font);\n  }\n  \n  .dashboard-item p {\n    font-size: var(--big-font);\n  }\n  \n  .dashboard-item:hover {\n    background: var(--hover-color);\n  }\n  \n  .dashboard.dark {\n    background-color: #333;\n    color: #fff;\n  }\n  \n  .dashboard.light {\n    background-color: #fff;\n    color: #333;\n  }\n  \n  /* Estilos adicionales para botones y gráficos si es necesario */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
