import React from 'react';

const Article = ({ article, onReadMore }) => {
  // Crear un resumen del contenido (100 caracteres)
  const previewContent = article.content.length > 100 
    ? article.content.substring(0, 100) + '...'
    : article.content;

  return (
    <article key={article.id}>
      <h2>{article.title}</h2>
      {article.date && <p className="date">{article.date}</p>}
      <p>{previewContent}</p>
      <button onClick={() => onReadMore(article.id)} className="read-more">
        Leer más...
      </button>
    </article>
  );
};

export default Article;
