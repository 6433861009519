import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './ArticleDetail.css';

const ArticleDetail = () => {
    const { id } = useParams();
    const [article, setArticle] = useState(null);
  
    useEffect(() => {
      fetch(`https://tempestgf.zapto.org/api/articles/${id}`)
        .then(response => response.json())
        .then(data => setArticle(data))
        .catch(error => console.error('Error fetching article:', error));
    }, [id]);
  
    if (!article) {
      return <div>Loading...</div>;
    }
  
    return (
      <div className="article-detail-container">
        <h1>{article.title}</h1>
        <p>{article.date}</p>
        <div dangerouslySetInnerHTML={{ __html: article.content }} />
      </div>
    );
  };
  
  export default ArticleDetail;
  
