import React, { useState } from 'react';
import axios from 'axios';
import './Post.css';

const Post = () => {
  const [titulo, setTitulo] = useState('');
  const [contenido, setContenido] = useState('');
  const [categoria, setCategoria] = useState('');
  const [tags, setTags] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        'https://tempestgf.zapto.org/api/publicaciones',
        {
          titulo,
          contenido,
          categoria,
          tags: tags.split(',').map(tag => tag.trim()),
        },
        {
          withCredentials: true  // Importante para enviar las cookies de sesión
        }
      );
      console.log('Publicación creada:', response.data);
      // Maneja la respuesta como sea necesario (redirección, mensaje de éxito, etc.)
    } catch (error) {
      console.error('Error al crear la publicación:', error);
      // Maneja el error como sea necesario (mostrar mensaje de error, etc.)
    }
  };

  return (
    <div className="nueva-publicacion">
      <h2>Crear Nueva Publicación</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="titulo">Título:</label>
          <input
            type="text"
            id="titulo"
            name="titulo"
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="contenido">Contenido:</label>
          <textarea
            id="contenido"
            name="contenido"
            value={contenido}
            onChange={(e) => setContenido(e.target.value)}
            rows="4"
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="categoria">Categoría:</label>
          <select
            id="categoria"
            name="categoria"
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
            required
          >
            <option value="">Selecciona una categoría</option>
            <option value="Programacion">Programacion</option>
            <option value="Hacking">Hacking Etico</option>
            <option value="Hardware">Hacking</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="tags">Tags (separados por comas):</label>
          <input
            type="text"
            id="tags"
            name="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </div>
        <button type="submit" className="btn-submit">
          Publicar
        </button>
      </form>
    </div>
  );
};

export default Post;
