import React, { useState } from 'react';
import './AI.css'; // Importa el archivo de estilos específicos para AI

const AI = () => {
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => setMessage(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const res = await fetch('https://tempestgf.zapto.org/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message })
      });

      if (!res.body) {
        throw new Error('Streaming not supported');
      }

      let result = '';
      const reader = res.body.getReader();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += new TextDecoder().decode(value);
        setResponse(result); // Update UI with each chunk of message received
      }

      setMessage(''); // Clear message input after sending
    } catch (error) {
      console.error('Error in request:', error);
      setError('Error communicating with server');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ai-container">
      <div className="ai-content">
        <h1>Interact with AI</h1>
        <form onSubmit={handleSubmit} className="ai-input-container">
          <input
            type="text"
            value={message}
            onChange={handleChange}
            placeholder="Type your message..."
            disabled={loading}
            className="ai-input"
          />
          <button type="submit" disabled={loading} className="ai-button">
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
        {loading && <div className="ai-loading"></div>}
        {error && <p className="ai-error-message">{error}</p>}
        {response && (
          <div className="ai-conversation-container">
            <p className="ai-message">{response}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AI;
