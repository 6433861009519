// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form {
	display: grid;
	place-items: center;
	margin-top: 2rem;
}
.contact-form form {
	display: flex;
	flex-direction: column;
	width: 300px;
}
form input,
textarea {
	padding: 12px;
	border-radius: 0.5rem;
	width: 100%;
	border: none;
	outline: none;
	background: var(--secondary-color);
	margin-bottom: 1rem;
	color: var(--text-color);
}
form input::placeholder,
textarea::placeholder {
	color: var(--text-color);
}
form textarea {
	resize: none;
	height: 200px;
}
.contact-button {
	width: 80px;
	cursor: pointer;
	background: var(--main-color);
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.contact-button:hover {
	background: var(--hover-color);
}`, "",{"version":3,"sources":["webpack://./src/components/Contact/Contact.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,gBAAgB;AACjB;AACA;CACC,aAAa;CACb,sBAAsB;CACtB,YAAY;AACb;AACA;;CAEC,aAAa;CACb,qBAAqB;CACrB,WAAW;CACX,YAAY;CACZ,aAAa;CACb,kCAAkC;CAClC,mBAAmB;CACnB,wBAAwB;AACzB;AACA;;CAEC,wBAAwB;AACzB;AACA;CACC,YAAY;CACZ,aAAa;AACd;AACA;CACC,WAAW;CACX,eAAe;CACf,6BAA6B;CAC7B,WAAW;CACX,gBAAgB;CAChB,yBAAyB;CACzB,mBAAmB;AACpB;AACA;CACC,8BAA8B;AAC/B","sourcesContent":[".contact-form {\n\tdisplay: grid;\n\tplace-items: center;\n\tmargin-top: 2rem;\n}\n.contact-form form {\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: 300px;\n}\nform input,\ntextarea {\n\tpadding: 12px;\n\tborder-radius: 0.5rem;\n\twidth: 100%;\n\tborder: none;\n\toutline: none;\n\tbackground: var(--secondary-color);\n\tmargin-bottom: 1rem;\n\tcolor: var(--text-color);\n}\nform input::placeholder,\ntextarea::placeholder {\n\tcolor: var(--text-color);\n}\nform textarea {\n\tresize: none;\n\theight: 200px;\n}\n.contact-button {\n\twidth: 80px;\n\tcursor: pointer;\n\tbackground: var(--main-color);\n\tcolor: #fff;\n\tfont-weight: 500;\n\ttext-transform: uppercase;\n\tletter-spacing: 1px;\n}\n.contact-button:hover {\n\tbackground: var(--hover-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
