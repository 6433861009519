// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* About.css */
.about-container {
    width: 50%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem;
}

.about-img img {
	width: 80%;
	border-radius: 0.5rem;
}

.about-text p {
	font-size: var(--p-font);
	font-weight: 400;
	text-align: justify;
}

.information {
	margin: 1rem 0 1.4rem;
}

.information .info-box {
	display: flex;
	margin-bottom: 1.4rem;
}

.info-box a:hover {
	text-decoration: none;
}

.information .info-box .bx {
	font-size: 22px;
}

.information .info-box span, .information .info-box a {
	font-weight: 400;
	margin-left: 1rem;
	color: var(--text-color);
}

.information .info-box a:hover {
	color: var(--hover-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/About/About.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,UAAU;IACV,iBAAiB;IACjB,cAAc;IACd,aAAa;AACjB;;AAEA;CACC,UAAU;CACV,qBAAqB;AACtB;;AAEA;CACC,wBAAwB;CACxB,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,aAAa;CACb,qBAAqB;AACtB;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,gBAAgB;CAChB,iBAAiB;CACjB,wBAAwB;AACzB;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":["/* About.css */\n.about-container {\n    width: 50%;\n    max-width: 1000px;\n    margin: 0 auto;\n    padding: 2rem;\n}\n\n.about-img img {\n\twidth: 80%;\n\tborder-radius: 0.5rem;\n}\n\n.about-text p {\n\tfont-size: var(--p-font);\n\tfont-weight: 400;\n\ttext-align: justify;\n}\n\n.information {\n\tmargin: 1rem 0 1.4rem;\n}\n\n.information .info-box {\n\tdisplay: flex;\n\tmargin-bottom: 1.4rem;\n}\n\n.info-box a:hover {\n\ttext-decoration: none;\n}\n\n.information .info-box .bx {\n\tfont-size: 22px;\n}\n\n.information .info-box span, .information .info-box a {\n\tfont-weight: 400;\n\tmargin-left: 1rem;\n\tcolor: var(--text-color);\n}\n\n.information .info-box a:hover {\n\tcolor: var(--hover-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
