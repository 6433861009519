import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import About from './components/About/About';
import Skills from './components/Skills/Skills';
import Contact from './components/Contact/Contact';
import Services from './components/Services/Services';
import Footer from './components/Footer/Footer';
import { Matrix } from './components/Utils/Matrix';
import Register from './components/Session/Register';
import UserProfile from './components/Profile/UserProfile';
import Blog from './components/Blog/Blog';
import Foro from './components/Forum/Foro';
import Post from './components/Forum/Post';
import DetallePublicacion from './components/Forum/DetallePublicacion';
import AI from './components/AI/AI'; 
import Admin from './components/Admin/Admin';
import Dashboard from './components/Admin/Dashboard';
import PublishArticle from './components/Blog/PublishArticle';
import ArticleDetail from './components/Blog/ArticleDetail';
import BlogPost from './components/Blog/BlogPost';
import PrivacyPolicy from './components/MobileWheel/PrivacyPolicy';
import Tests from './components/Tests/Tests';
import SearchPage from './components/Search/SearchPage'; // Importar el nuevo componente
import './App.css';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/Tests" element={<Tests />} />
        <Route path="/AI" element={<AI />} />
        <Route path="/new-post" element={<Post />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/forum" element={<Foro />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/publicaciones/:id" element={<DetallePublicacion />} />
        <Route path="/Admin" element={<Admin />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/publish" element={<PublishArticle />} />
        <Route path="/articles/:id" element={<ArticleDetail />} />
        <Route path="/blogpost" element={<BlogPost />} />
        <Route path="/MobileWheelPrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/search" element={<SearchPage />} /> {/* Añadir la ruta para la página de búsqueda */}
        
        <Route
          path="/"
          element={
            <>
              <Matrix />
              <Home />
              <About />
              <Skills />
              <Contact />
            </>
          }
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
