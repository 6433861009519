import { useEffect } from 'react';

// Matrix
export function Matrix() {
    useEffect(() => {
        const canvas = document.createElement("canvas");
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        document.body.appendChild(canvas);
        const ctx = canvas.getContext("2d");

        let fontSize = 16;
        const chars =
            "アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲンガギグゲゴザジズゼ ゾダヂヅデドバビブベボパピプペポ0123456789";

        const setup = () => {
            const cols = canvas.width / fontSize;
            let drops = []; // Define drops dentro de setup()
            for (let x = 0; x < cols; x++) {
                drops[x] = (Math.random() * canvas.height) / fontSize;
            }

            ctx.fillStyle = "#EEE";
            ctx.font = "48px monospace";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            return drops;
        }

        const draw = (drops) => {
            ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            if (document.body.classList.contains('active')) {
                ctx.fillStyle = "#F00";
            } else {
                ctx.fillStyle = "#8ecae6";
            }
            ctx.font = fontSize + "px monospace";
            for (let i = 0; i < drops.length; i++) {
                const text = chars[Math.floor(Math.random() * chars.length)];
                ctx.fillText(text, i * fontSize, drops[i] * fontSize);
                if (
                    drops[i] * fontSize > canvas.height - 200 * Math.random() &&
                    Math.random() > 0.975
                ) {
                    drops[i] = 0;
                }
                drops[i]++;
            }
        }

        const drops = setup(); // Obtener drops desde setup
        const intervalId = setInterval(() => draw(drops), 33);

        window.onresize = () => {
            setup();
        };

        return () => {
            clearInterval(intervalId); // Limpiar el intervalo
            document.body.removeChild(canvas);
        };
    }, []);

    return null; // Este componente no renderiza nada visualmente en React
}
