import React, { useEffect, useState, useCallback } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Article from './Article';
import { useNavigate } from 'react-router-dom';

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchArticles = useCallback(async () => {
    try {
      const response = await fetch('https://tempestgf.zapto.org/api/articles');
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const data = await response.json();
      // Ordenar artículos por fecha de publicación descendente
      const sortedArticles = data.sort(
        (a, b) => new Date(b.publish_date) - new Date(a.publish_date)
      );
      setArticles(sortedArticles);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchArticles();
    AOS.init({ duration: 1000, easing: 'ease-in-out' });
  }, [fetchArticles]);

  const handleReadMore = useCallback(
    (id) => {
      navigate(`/articles/${id}`);
    },
    [navigate]
  );

  return (
    <div className="font-source-code-pro text-[var(--text-color)] bg-[var(--bg-color)] min-h-screen py-24 sm:py-32">
      <div className="container mx-auto px-4 sm:px-6 lg:px-12">
        <main className="py-12 sm:py-16 px-4 sm:px-8 lg:px-10 bg-[var(--bg-color)] rounded-2xl shadow-xl hover:shadow-2xl transition-shadow duration-300">
          <div
            className="max-w-4xl mx-auto bg-[var(--bg-color)] rounded-3xl p-8 sm:p-12 lg:p-16 shadow-2xl"
            data-aos="fade-up"
          >
            <h1 className="text-center text-4xl sm:text-5xl lg:text-6xl font-extrabold mb-8 sm:mb-10 lg:mb-12 text-[var(--main-color)] tracking-tight">
              Blog
            </h1>
            {isLoading ? (
              <p className="text-center text-lg font-medium text-[var(--text-color)] animate-pulse">
                Cargando artículos...
              </p>
            ) : error ? (
              <p className="text-center text-lg font-medium text-red-500">
                Error al cargar artículos: {error}
              </p>
            ) : articles.length > 0 ? (
              <div
                className="space-y-8 sm:space-y-10 lg:space-y-12"
                data-aos="fade-up"
              >
                {articles.map((article) => (
                  <Article
                    key={article.id}
                    article={article}
                    onReadMore={handleReadMore}
                  />
                ))}
              </div>
            ) : (
              <p className="text-center text-lg font-medium text-[var(--text-color)]">
                No se encontraron artículos.
              </p>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Blog;
